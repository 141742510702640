import api from "./axios";

export default {
    async getMedicalRecords(patientId) {
        const response = await api.get(`/patients/${patientId}/medicalRecords`);
        return response.data
    },
    async addMedicalRecords(patientId, medicalRecord) {
        const response = await api.post(`/patients/${patientId}/medicalRecords`, medicalRecord)

        return response.data;
    },
    async updateMedicalRecords(patientId, medicalRecord) {
        const response = await api.put(`/patients/${patientId}/medicalRecords/${medicalRecord.id}`, medicalRecord)
        return response.data;
    },
}