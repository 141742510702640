import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import SoftUIDashboard from "./soft-ui-dashboard";

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'vue-multiselect/dist/vue-multiselect.css'
import { ptBR } from 'date-fns/locale';
import '@fortawesome/fontawesome-free/css/all.min.css';
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-default.css'
import VueTheMask from "vue-the-mask";

import './plugins/yup'


const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(SoftUIDashboard);
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.config.globalProperties.$datePickerLocale = ptBR;
appInstance.use(VueToast);
appInstance.use(VueTheMask);
appInstance.mount("#app");
