<!-- O gráfico de dispersão exibe pontos em um plano cartesiano para observar correlações entre variáveis. -->
<template>
    <TypedChart
      :type="'scatter'"
      :data="data"
      :options="options"
    />
  </template>
  
  <script>

import { Scatter } from 'vue-chartjs';
  
  export default {
    name: 'ScatterChart',
    components: { TypedChart: Scatter },
    props: {
      data: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  </script>