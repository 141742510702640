<!-- O gráfico de pizza é útil para mostrar partes de um todo de forma visualmente simples. -->
<template>
    <TypedChart
      :type="'pie'"
      :data="data"
      :options="options"
    />
  </template>
  
  <script>

import { Pie } from 'vue-chartjs';
  
export default {
    name: 'PieChart',
    components: { TypedChart: Pie },
    props: {
    data: {
        type: Object,
        required: true,
    },
    options: {
        type: Object,
        default: () => ({}),
    },
    },
};
</script>