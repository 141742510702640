<template>
  <div class="modal fade" id="medical_record_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Prontuário</h5>
        </div>
        <div class="modal-body">
          <div v-if="loading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <div v-else>
            <p><strong>Paciente:</strong> {{ patientName }}</p>
            <p><strong>Última atualização:</strong> {{ updatedAt }}</p>
            <textarea 
              v-model="medicalRecord.notes" 
              class="form-control" 
              rows="6" 
              placeholder="Digite as informações do prontuário..."
            ></textarea>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="onClose">Fechar</button>
          <button type="button" class="btn btn-success" @click="handleSubmit">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>{{ medicalRecord.id ? 'Atualizar' : 'Salvar' }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import { mapActions, mapState } from "vuex";
import showSwal from '../../mixins/showSwal';

export default {
  name: "MedicalRecordModal",
  data() {
    return {
      medical_record_modal: null,
      loading: false,
    };
  },
  mounted() {
    this.medical_record_modal = new bootstrap.Modal(`#medical_record_modal`, {
      backdrop: "static",
    });
  },
  watch: {
    open(newOpen) {
      if (newOpen) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  computed: {
    ...mapState("medicalRecords", {
      loading: (state) => state.loading,
      open: (state) => state.modal.open,
      patientId: (state) => state.modal.patientId,
      medicalRecord: (state) => state.form.medicalRecord || { notes: "" },
    }),
    ...mapState("patients", {
      patients: (state) => state.patients,
    }),
    patientName() {
      const patient = this.patients.find(p => p.id === this.patientId);
      return patient ? patient.name : "Paciente desconhecido";
    },
    updatedAt() {
      return this.medicalRecord.updated_at
        ? new Date(this.medicalRecord.updated_at).toLocaleString()
        : "Não houve atualizações";
    },
  },
  methods: {
    ...mapActions("medicalRecords", {
      onClose: "closeModal",
      addMedicalRecords: "addMedicalRecords",
      updateMedicalRecords: "updateMedicalRecords",
    }),
    openModal() {
      this.medical_record_modal.show();
    },
    closeModal() {
      this.medical_record_modal.hide();
    },
    async handleSubmit() {
      this.loading = true;
      try {
        const data = {
          patientId: this.patientId,
          medicalRecord: {
            id: this.medicalRecord.id,
            notes: this.medicalRecord.notes,
          },
        };

        if (this.medicalRecord.id) {
          await this.updateMedicalRecords(data);
        } else {
          await this.addMedicalRecords(data);
        }

        showSwal.methods.showSwal({
          type: 'success',
          message: 'Prontuário salvo com sucesso!',
          timer: 5000,
        });

        await this.onClose();

      } catch (error) {
        console.error(error);
        showSwal.methods.showSwal({
          type: 'error',
          message: 'Erro ao salvar o prontuário. Tente novamente.',
          timer: 5000,
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>
