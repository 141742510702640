import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_BASE_URL + '/patients';

export default {
    async getExams(patientId) {
        const url = `${API_URL}/${patientId}/exams`
        const response = await axios.get(url, {
            headers: authHeader()
        });
        return response.data;
    },
    async addExam(patientId, exam) {
        const formData = new FormData();
        for (const key in exam) {
            formData.append(key, exam[key]);
        }

        const url = `${API_URL}/${patientId}/exams`
        const response = await axios.post(url, formData, {
            headers: authHeader()
        })

        return response.data;
    },
    async addRetinography(retinography) {
        const formData = new FormData();
        for (const key in retinography) {
            formData.append(key, retinography[key]);
        }
        
        const url = `${process.env.VUE_APP_API_BASE_URL}/add-retinography`
        const response = await axios.post(url, formData, {
            headers: authHeader()
        })

        return response.data;
    },
    async updateExam(patientId, exam) {
        const formData = new FormData();
        for (const key in exam) {
            if (exam[key]) formData.append(key, exam[key]);
        }

        const url = `${API_URL}/${patientId}/exams/${exam.id}`
        const response = await axios.post(url, formData, {
            headers: authHeader()
        })


        return response.data;
    },

    async sendExamResult (examId) {
        const url = `${process.env.VUE_APP_API_BASE_URL}/exams/${examId}/send-result`;
        const response = await axios.post(url, null, {
            headers: authHeader()
        })

        return response.data;
    }
}