<!-- O gráfico de rosca mostra proporções de um total, semelhante ao gráfico de pizza. -->
<template>
    <TypedChart
      :type="'doughnut'"
      :data="data"
      :options="options"
    />
  </template>
  
<script>

import { Doughnut } from 'vue-chartjs';

export default {
    name: 'DoughnutChart',
    components: { TypedChart: Doughnut },
    props: {
        data: {
        type: Object,
        required: true,
        },
        options: {
        type: Object,
        default: () => ({}),
        },
    },
};
</script>