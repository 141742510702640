export const VisualAcuity = [
    "20/20 (visão normal)",
    "20/25 (visão normal)",
    "20/30 (visão normal)",
    "20/40 (baixa visão)",
    "20/50 (baixa visão)",
    "20/70 (baixa visão)",
    "20/100 (baixa visão)",
    "20/200 (baixa visão severa)",
    "Percepção de Luz (PL) - (baixa visão severa)",
    "Sem Percepção de Luz (SPL) - (baixa visão severa)",
];

export const VisualAcuityOptions = VisualAcuity.map(va => ({
    label: va,
    value: va
}));

export const RefractiveDisorders = [
    "Miopia",
    "Hipermetropia",
    "Astigmatismo"
];

export const RefractiveDisordersOptions = RefractiveDisorders.map(rd => ({
    label: rd,
    value: rd
}));