<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card Header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between align-items-center">
              <h5 class="mb-0" @click="startAdding">Atendimentos</h5>
              <div class="d-flex align-items-center mt-4 mt-lg-0">
                <input
                  type="text"
                  class="form-control"
                  v-model="searchQuery"
                  @input="searchSupports"
                  placeholder="Buscar..."
                />&nbsp;&nbsp;
                <!-- <a class="mb-0 btn bg-gradient-success btn-sm">
                  Gerar Excel - Adicionar após corrigir a inicialização de equipamentos
                </a>&nbsp;&nbsp; -->
                <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm me-2">
                  + Novo Atendimento
                </a>
              </div>
            </div>
          </div>

          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>

          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="equipaments-list" ref="equipamentsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByName('assigned_user_name')" class="click">
                      <strong>
                        Técnico&nbsp;
                        <span>
                          <i :class="{
                            'fa fa-chevron-up': sortNameOrder === 1,
                            'fa fa-chevron-down': sortNameOrder === 2,
                            'fa fa-bars': sortNameOrder === 0
                          }"></i>
                        </span>
                      </strong>
                    </th>
                    <th @click="sortByName('company_name')" class="click">
                      <strong>
                        Empresa&nbsp;
                        <span>
                          <i :class="{
                            'fa fa-chevron-up': sortNameOrder === 1,
                            'fa fa-chevron-down': sortNameOrder === 2,
                            'fa fa-bars': sortNameOrder === 0
                          }"></i>
                        </span>
                      </strong>
                    </th>
                    <th @click="sortByName('data_atendimento')" class="click">
                      <strong>
                        Data Atendimento&nbsp;
                        <span>
                          <i :class="{
                            'fa fa-chevron-up': sortNameOrder === 1,
                            'fa fa-chevron-down': sortNameOrder === 2,
                            'fa fa-bars': sortNameOrder === 0
                          }"></i>
                        </span>
                      </strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <supports-tile
                    v-for="support in supports"
                    :key="support.id"
                    :support="support"
                    @start-editing="startEditing"
                  />
                </tbody>
              </table>
            </div>
          </div>

          <pagination 
            :current="page"
            :perPage="perPage"
            :onChangePage="changeToPage"
            :totalPages="lastPage"
            :totalValues="total"
            :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <supports-modal />
</template>

<script>
import list_mixin from '../mixins/list_mixin';
import supportsService from '@/services/supports.service.js';
import SupportsTile from './components/SupportsTile.vue';  
import SupportsModal from './components/SupportsModal.vue';
import Pagination from '../components/Pagination.vue';

export default {
  name: "Supports",
  components: {
    SupportsTile,
    SupportsModal,  
    Pagination
  },

  mixins: [list_mixin('supports')],

  data() {
    return {
      supports: [],
      page: 1,
      perPage: 10,
      total: 0,
      lastPage: 1,
      loading: false,
      searchQuery: '',
      sortNameOrder: 0, 
    };
  },

  computed: {
    storeSupports() {
      return this.$store.state.supports.supports;
    }
  },

  watch: {
    storeSupports: {
      handler(newSupports) {
        this.supports = newSupports;
      },
      deep: true
    }
  },

  methods: {
    startAdding() {
      this.$store.dispatch('supports/startAdding');
    },

    async addSupports() {
      this.loading = true;
      try {
        const response = await supportsService.addSupports(this.page);
        this.supports = response.data;
        this.total = response.total;
        this.lastPage = response.last_page;
      } catch (error) {
        console.error("Erro ao adicionar um novo Atendimento", error);
      } finally {
        this.loading = false;
      }
    },

    async loadSupports() {
      this.loading = true;
      try {
        await this.$store.dispatch("supports/getSupports");
        const supportState = this.$store.state.supports;
        this.supports = supportState.supports;
        this.total = supportState.total;
        this.lastPage = supportState.lastPage;
      } catch (error) {
        console.error("Erro ao carregar os atendimentos", error);
      } finally {
        this.loading = false;
      }
    },

    async searchSupports() {
      this.page = 1; 
      await this.loadSupports(); 
    },

    changeToPage(page) {
      this.page = page;
      this.loadSupports();
    },

    changePerPage(perPage) {
      this.perPage = perPage;
      this.loadSupports();
    },

    sortByName(column) {
      if (this.sortNameOrder === 0) {
        this.sortNameOrder = 1; 
      } else if (this.sortNameOrder === 1) {
        this.sortNameOrder = 2; 
      } else {
        this.sortNameOrder = 0; 
      }
      this.supports.sort((a, b) => {
        if (this.sortNameOrder === 1) {
          return a[column] > b[column] ? 1 : -1;
        } else if (this.sortNameOrder === 2) {
          return a[column] < b[column] ? 1 : -1;
        } else {
          return 0;
        }
      });
    },
  },
  mounted() {
    this.loadSupports();
  },

  async created() {
    try {
      await this.$store.dispatch("supports/getSupports");
      } catch (error) {
        console.error(error);
      }
  },
};
</script>

<style scoped>
td {
  padding: 12px 24px !important;
}
</style>
