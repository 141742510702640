<!-- O gráfico de linha é usado para visualizar tendências ao longo do tempo. -->
<template>
    <TypedChart
      :type="'line'"
      :data="data"
      :options="options"
    />
</template>

<script>

import { Line } from 'vue-chartjs';
  
export default {
    name: 'LineChart',
    components: { TypedChart: Line },
    props: {
        data: {
        type: Object,
        required: true,
        },
        options: {
        type: Object,
        default: () => ({}),
        },
    },
};
</script>