import dashboardService from "../services/dashboard.service"

const initialState = {
  dashboardData: {},
  triageStats: {},
  examStats: {},
  loading: false,
  genderStats: {},
  ageStats: {},
  conditionsStats: {},
  healthHabitsStats: {},
  screenTimeStats: {},
  eyeComplaintsStats: {},
  eyeDiseasesStats: {},
  visionAidsStats: {},
  eyeSurgeriesStats: {},
  visionQualityStats: {},
  retinographyStats: {},
  currentCompanyId: null
}

export const dashboard = {
  namespaced: true,
  state: initialState,
  actions: {
    async getPatientsCount({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getPatientsCount(state.currentCompanyId);
      commit("SET_DASHBOARD_DATA", response);
    },
    async getTriageStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getTriageStatistics(state.currentCompanyId);
      commit("SET_TRIAGE_STATS", response);
    },
    async getExamStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getExamStatistics(state.currentCompanyId);
      commit("SET_EXAM_STATS", response);
    },
    async getGenderStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getGenderStatistics(state.currentCompanyId);
      commit("SET_GENDER_STATS", response);
    },
    async getAgeStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getAgeStatistics(state.currentCompanyId);
      commit("SET_AGE_STATS", response);
    },
    async getConditionsStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getConditionsStatistics(state.currentCompanyId);
      commit("SET_CONDITIONS_STATS", response.conditions_statistics);
    },
    async getHealthHabitsStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getHealthHabitsStatistics(state.currentCompanyId);
      commit("SET_HEALTH_HABITS_STATS", response);
    },
    async getScreenTimeStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getScreenTimeStatistics(state.currentCompanyId);
      commit("SET_SCREEN_TIME_STATS", response);
    },
    async getEyeComplaintsStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getEyeComplaintsStatistics(state.currentCompanyId);
      commit("SET_EYE_COMPLAINTS_STATS", response);
    },
    async getEyeDiseasesStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getEyeDiseasesStatistics(state.currentCompanyId);
      commit("SET_EYE_DISEASES_STATS", response);
    },
    async getVisionAidsStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getVisionAidsStatistics(state.currentCompanyId);
      commit("SET_VISION_AIDS_STATS", response);
    },
    async getEyeSurgeriesStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getEyeSurgeriesStatistics(state.currentCompanyId);
      commit("SET_EYE_SURGERIES_STATS", response);
    },
    async getVisionQualityStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getVisionQualityStatistics(state.currentCompanyId);
      commit("SET_VISION_QUALITY_STATS", response);
    },
    async getRetinographyStatistics({ state, commit }) {
      commit("SET_LOADING", true);
      const response = await dashboardService.getRetinographyStatistics(state.currentCompanyId);
      commit("SET_RETINOGRAPHY_STATS", response);
    },
    async filterByCompany({ commit, dispatch }, companyId) {
      commit("SET_CURRENT_COMPANY", companyId);
      await Promise.all([
        dispatch('getPatientsCount'),
        dispatch('getTriageStatistics'),
        dispatch('getExamStatistics'),
        dispatch('getGenderStatistics'),
        dispatch('getAgeStatistics'),
        dispatch('getConditionsStatistics'),
        dispatch('getHealthHabitsStatistics'),
        dispatch('getScreenTimeStatistics'),
        dispatch('getEyeComplaintsStatistics'),
        dispatch('getEyeDiseasesStatistics'),
        dispatch('getVisionAidsStatistics'),
        dispatch('getEyeSurgeriesStatistics'),
        dispatch('getVisionQualityStatistics'),
        dispatch('getRetinographyStatistics')
      ]);
    },

    async resetFilter({ commit, dispatch }) {
      commit("SET_CURRENT_COMPANY", null);
      await Promise.all([
        dispatch('getPatientsCount'),
        dispatch('getTriageStatistics'),
        dispatch('getExamStatistics'),
        dispatch('getGenderStatistics'),
        dispatch('getAgeStatistics'),
        dispatch('getConditionsStatistics'),
        dispatch('getHealthHabitsStatistics'),
        dispatch('getScreenTimeStatistics'),
        dispatch('getEyeComplaintsStatistics'),
        dispatch('getEyeDiseasesStatistics'),
        dispatch('getVisionAidsStatistics'),
        dispatch('getEyeSurgeriesStatistics'),
        dispatch('getVisionQualityStatistics'),
        dispatch('getRetinographyStatistics')
      ]);
    }
  },
  mutations: {
    SET_DASHBOARD_DATA(state, data) {
      state.dashboardData = data;
      state.loading = false;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_TRIAGE_STATS(state, data) {
      state.triageStats = data;
      state.loading = false;
    },
    SET_EXAM_STATS(state, data) {
      state.examStats = data;
      state.loading = false;
    },
    SET_GENDER_STATS(state, data) {
      state.genderStats = data;
      state.loading = false;
    },
    SET_AGE_STATS(state, data) {
      state.ageStats = data;
      state.loading = false;
    },
    SET_CONDITIONS_STATS(state, data) {
      state.conditionsStats = data;
      state.loading = false;
    },
    SET_HEALTH_HABITS_STATS(state, data) {
      state.healthHabitsStats = data;
      state.loading = false;
    },
    SET_SCREEN_TIME_STATS(state, data) {
      state.screenTimeStats = data;
      state.loading = false;
    },
    SET_EYE_COMPLAINTS_STATS(state, data) {
      state.eyeComplaintsStats = data;
      state.loading = false;
    },
    SET_EYE_DISEASES_STATS(state, data) {
      state.eyeDiseasesStats = data;
      state.loading = false;
    },
    SET_VISION_AIDS_STATS(state, data) {
      state.visionAidsStats = data;
      state.loading = false;
    },
    SET_EYE_SURGERIES_STATS(state, data) {
      state.eyeSurgeriesStats = data;
      state.loading = false;
    },
    SET_VISION_QUALITY_STATS(state, data) {
      state.visionQualityStats = data;
      state.loading = false;
    },
    SET_RETINOGRAPHY_STATS(state, data) {
      state.retinographyStats = data;
      state.loading = false;
    },
    SET_CURRENT_COMPANY(state, companyId) {
      state.currentCompanyId = companyId;
    }
  }
}
