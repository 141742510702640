<!-- O gráfico de radar é útil para mostrar dados multidimensionais em um formato radial. -->
<template>
    <TypedChart
      :type="'radar'"
      :data="data"
      :options="options"
    />
  </template>
  
  <script>

import { Radar } from 'vue-chartjs';
  
  export default {
    name: 'RadarChart',
    components: { TypedChart: Radar },
    props: {
      data: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  </script>
  