<template>
  <div
    class="tab-pane fade show active"
    id="data"
    role="tabpanel"
    aria-labelledby="data-tab"
  >
    <div class="row">
      <div class="col-12 mb-3">
        <label class="form-label">Data de Criação</label>
        <input
          type="text"
          class="form-control"
          :value="formatDate(modelValue.created_at)"
          disabled
        />
      </div>
    </div>

    <label for="name">Nome da Empresa<span class="required">*</span></label>
      <soft-field
        id="company_name"
        v-model="lead.company_name"
        type="text"
        placeholder="Empresa"
        name="company_name"
    /> 

    <label for="name">Nome para Contato<span class="required">*</span></label>
      <soft-field
          id="name"
          v-model="lead.name"
          type="text"
          placeholder="Nome"
          name="name"
      /> 
    <label for="email">Email<span class="required">*</span></label>
      <soft-field
          id="email"
          v-model="lead.email"
          type="email"
          placeholder="Email"
          name="email"
      />
    <label for="phone">Telefone</label>
      <soft-field
          id="phone"
          v-model="lead.phone"
          type="text"
          placeholder="Telefone"
          name="phone"
      />
    <label for="state">Estado</label>
    <div class="select-search">
      <input
        type="text"
        class="form-control"
        v-model="stateSearch"
        placeholder="Digite ou selecione o estado"
        @focus="showStateList = true"
        @input="filterStates"
      />
      <div class="select-options" v-if="showStateList && filteredStates.length">
        <div
          v-for="state in filteredStates"
          :key="state.value"
          class="select-option"
          @click="selectState(state)"
        >
          {{ state.label }}
        </div>
      </div>
    </div>

    <label for="city">Cidade</label>
    <div class="select-search">
      <input
        type="text"
        class="form-control"
        v-model="citySearch"
        placeholder="Digite ou selecione a cidade"
        @focus="showCityList = true"
        @input="filterCities"
        :disabled="!lead.state"
      />
      <div class="select-options" v-if="showCityList && filteredCities.length">
        <div
          v-for="city in filteredCities"
          :key="city.value"
          class="select-option"
          @click="selectCity(city)"
        >
          {{ city.label }}
        </div>
      </div>
    </div>
    <label for="zone">Região</label>
      <soft-field
          id="zone"
          v-model="lead.zone"
          type="text"
          placeholder="Região"
          name="zone"
      />
    <!-- <label for="company_id">Empresa</label>
      <soft-select-search
          v-if="companies && companies.length > 0"
          id="company_id"
          v-model="lead.company_id"
          placeholder="Empresa"
          name="company_id"
          :options="companies"
      /> -->

    <label for="source">Origem</label>
      <select
          id="source"
          v-model="lead.source"
          name="source"
          class="form-select"
      >
          <option value="" disabled>Selecione a origem</option>
          <option value="Instagram">Instagram</option>
          <option value="Site">Site</option>
          <option value="Prospecção">Prospecção</option>
          <option value="Indicação">Indicação</option>
          <option value="Google">Google</option>
      </select>
    <label for="funnel_stage">Fase do Funil</label>
      <select
          id="funnel_stage"
          v-model="lead.funnel_stage"
          name="funnel_stage"
          class="form-select"   
      >
          <option value="" disabled>Selecione a fase do funil</option>
          <option value="Oportunidade">Oportunidade</option>
          <option value="Contato 1">Contato 1</option>
          <option value="Contato 2">Contato 2</option>
          <option value="Contato 3">Contato 3</option>
          <option value="Expectativa 1º atendimento">Expectativa 1º atendimento</option>
          <option value="Reunião">Reunião</option>
          <option value="Retorno Reunião">Retorno Reunião</option>
          <option value="Fechamento">Fechamento</option>
          <option value="Agendamento exame">Agendamento exame</option>
      </select>
    <label for="temperature_stage">Temperatura do Funil</label>
      <select
          id="temperature_stage"
          v-model="lead.temperature_stage"
          name="temperature_stage"
          class="form-select"   
      >
          <option value="" disabled>Selecione a temperatura do funil</option>
          <option value="Frio">Frio</option>
          <option value="Morno">Morno</option>
          <option value="Quente">Quente</option>
      </select>
    <!-- <label for="assigned_user_id">Usuário Responsável</label>
      <soft-select-search
          id="assigned_user_id"
          v-model="lead.assigned_user_id"
          placeholder="Usuário Responsável"
          name="assigned_user_id"
          :options="users"
      /> -->
    <label for="notes">Notas</label>
      <soft-field
          id="notes"
          v-model="lead.notes"
          type="text"
          placeholder="Notas"
          name="notes"
      /> 
      <label for="assigned_user" class="form-label">Vendedor Responsável</label>
      <select 
        class="form-control"
        id="assigned_user"
        v-model="lead.assigned_user_id"
      >
        <option value="">Selecione um vendedor</option>
        <option v-for="user in salesUsers" :key="user.id" :value="user.id">
          {{ user.name }} ({{ getUserType(user.type) }})
        </option>
      </select>
  </div>
</template>
  
<script>
  // import SoftSwitchModel from "../../components/SoftSwitchModel.vue";
  // import SoftSelect from "../../components/SoftSelect";
  import "vue-search-select/dist/VueSearchSelect.css"
  import SoftField from "../../components/SoftField.vue";
  import locationService from "../../services/location.service";
  // import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

  export default {
    name: "LeadDataTab",
    props: ['modelValue', 'getUserType', 'salesUsers'],
    components: {
      SoftField,
      // SoftSelectSearch,
    },
  data() {
    return {
      states: [],
      cities: [],
      stateSearch: '',
      citySearch: '',
      showStateList: false,
      showCityList: false,
      filteredStates: [],
      filteredCities: [],
      stateCode: null,
      defaultLead: {
        company_name: '',
        name: '',
        email: '',
        phone: '',
        state: '',
        city: '',
        zone: '',
        source: '',
        funnel_stage: '',
        temperature_stage: '',
        notes: '',
        created_at: null
      }
    };
  },
  computed: {
    lead: {
      get() {
        return this.modelValue || this.defaultLead;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.stateSearch = newValue.state || '';
          this.citySearch = newValue.city || '';
          if (newValue.state) {
            this.initializeStateAndCity();
          }
        } else {
          this.stateSearch = '';
          this.citySearch = '';
          this.stateCode = null;
        }
      }
    },
    stateCode: {
      async handler(newStateCode) {
        if (newStateCode) {
          await this.getCitiesForState();
        } else {
          this.cities = [];
          this.filteredCities = [];
        }
      }
    }
  },
  async created() {
    try {
      await this.initializeStateAndCity();
    } catch (error) {
      console.error('Error in created:', error);
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('pt-BR');
    },
    async onStateChange() {
      this.lead.city = '';
    },
    filterStates() {
      const search = this.stateSearch.toLowerCase();
      this.filteredStates = this.states.filter(state => 
        state.label.toLowerCase().includes(search) || 
        state.value.toLowerCase().includes(search)
      );
    },
    filterCities() {
      const search = this.citySearch.toLowerCase();
      this.filteredCities = this.cities.filter(city =>
        city.label.toLowerCase().includes(search)
      );
    },
    selectState(state) {
      this.lead.state = state.label;
      this.stateSearch = state.label;
      this.stateCode = state.value;
      this.showStateList = false;
      
      if (this.lead.city && this.stateSearch !== this.modelValue?.state) {
        this.lead.city = '';
        this.citySearch = '';
      }
    },
    selectCity(city) {
      this.lead.city = city.value;
      this.citySearch = city.label;
      this.showCityList = false;
    },
    handleClickOutside(event) {
      if (!event.target.closest('.select-search')) {
        this.showStateList = false;
        this.showCityList = false;
      }
    },
    async getCitiesForState() {
      if (this.stateCode) {
        try {
          const cities = await locationService.getCitiesByState(this.stateCode);
          this.cities = cities;
          this.filteredCities = cities;
        } catch (error) {
          console.error('Error loading cities:', error);
          this.cities = [];
          this.filteredCities = [];
        }
      }
    },

    async initializeStateAndCity() {
      try {
        if (this.states.length === 0) {
          this.states = await locationService.getStates();
          this.filteredStates = this.states;
        }

        const foundState = this.states.find(
          state => state.label.toLowerCase() === this.lead.state?.toLowerCase()
        );

        if (foundState) {
          this.stateCode = foundState.value;
          const cities = await locationService.getCitiesByState(this.stateCode);
          this.cities = cities;
          this.filteredCities = cities;
        }
      } catch (error) {
        console.error('Error initializing state and city:', error);
      }
    },
  }
};
</script>

<style scoped>
.form-select {
  margin-bottom: 1rem;
}
.select-search {
  position: relative;
  margin-bottom: 1rem;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ddd;
  border-top: none;
  border-radius: 0 0 4px 4px;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.select-option {
  padding: 8px 12px;
  cursor: pointer;
}

.select-option:hover {
  background-color: #f5f5f5;
}

.form-control {
  margin-bottom: 0;
}
</style>
