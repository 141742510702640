export const PersonalHistories = {
    HYPERTENSION: "Pressão Alta",
    DIABETES: "Diabetes",
    ARTHRITIS: "Artrite / Reumatismo (Dor nas articulações)",
    THYROID_DISEASE: "Disfunção na Tireóide",
    PREGNANCY: "Estou Gestante",
    BREASTFEEDING: "Estou Amamentando",
    OTHERS: "Outros",
    NOTHING: "Nenhum Histórico",
};

export default PersonalHistories;

export const PersonalHistoriesOptions = Object.values(PersonalHistories).map(value => ({
    value: value,
    label: value,
}));
