<template>
  <tr @click="startEditing">
    <td>{{ name }}</td>
    <td>{{ company.cnpj }}</td>
    <td>{{ type }}</td>
    <td>{{ address_city }}</td>
    <td>{{ active }}</td>
    <td class="d-flex gap-2">
      <button 
        class="btn btn-success"
        @click.stop="tokenGenerate()"
        data-toggle="tooltip"
        data-placement="top"
        title="Gerar link de anamnese para pacientes"
      >
        <font-awesome-icon :icon="['fas', 'link']" />
      </button>
    </td>
  </tr>
</template>

<script>
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { CompanyTypesName } from '../../constants/CompanyTypes';
import showSwal from "/src/mixins/showSwal.js";

library.add(faLink);

export default {
  props: ['company'],
  components: {
    FontAwesomeIcon,
  },
  computed: {
    cnpj() {
      return this.company.cnpj;
    },
    type() {
      return CompanyTypesName[this.company.type] ?? "Não definido";
    },
    name() {
      return this.company.corporate_reason;
    },

    address_city() {
      return this.company.address_city;
    },
    active() {
      return this.company.active ? "Sim" : "Não";
    },
    created_at() {
      return moment(this.company.created_at).format("DD/MM/YYYY HH:mm");
    }
  },
  methods: {
    startEditing() {
      this.$store.dispatch('companies/startEditing', this.company);
    },

    async tokenGenerate() {
      try {
        const data = await this.$store.dispatch('companies/tokenGenerate', this.company.id);
        
        if (this.error == null) {
          const tempUrl = `${window.location.origin}/externalRegistration?token=${encodeURIComponent(data.token)}`;
          await navigator.clipboard.writeText(tempUrl);
          showSwal.methods.showSwal({
            type: "success",
            message: "Link copiado para área de transferência.",
            width: 500,
          });
        }
      } catch (error) {
        return error
      }
    },
  }
}
</script>

<style scoped>
/* Estilos específicos podem ser adicionados aqui, se necessário */
</style>
