import medicalRecordsService from "../services/medicalRecords.service"
import { parseFormError } from "../services/utils";

const initialState = {
  medicalRecords: {},
  loading: false,
  modal: {
    open: false,
    patientId: null,
  },
  form: {
    open: false,
    patientId: null,
    medicalRecord: null,
    error: null
  }
}

export const medicalRecords = {
  namespaced: true,
  state: initialState,
  actions: {
    async getMedicalRecords({ commit }, patientId) {
      commit("SET_LOADING", true);
      const response = await medicalRecordsService.getMedicalRecords(patientId);
      const data = { patientId, medicalRecords: response };
      commit("SET_MEDICALRECORDS", data);
    },
    async showModal({ commit, dispatch, state }, patientId) {
      commit("SET_MODAL", { open: true, patientId });
      await dispatch("getMedicalRecords", patientId);
      const records = state.medicalRecords[patientId];
      if (records && records.length > 0) {
        commit("SET_FORM", { open: true, patientId, medicalRecord: records[0] });
      } else {
        commit("SET_FORM", { open: true, patientId, medicalRecord: { notes: "" } });
      }
    },
    async closeModal({ commit }) {
      const value = { open: false, patientId: null };
      commit("SET_MODAL", value);
    },
    startAdding({ commit }, patientId) {
      const value = { open: true, patientId, medicalRecord: null };
      commit("SET_FORM", value);
    },
    async addMedicalRecords({ commit, dispatch }, { patientId, medicalRecord }) {
      try {
        commit("SET_FORM_ERROR", null);
        await medicalRecordsService.addMedicalRecords(patientId, medicalRecord);
        dispatch("stopEditing");
        dispatch("getMedicalRecords", patientId);
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async startEditing({ commit }, { medicalRecord, patientId }) {
      const value = { open: true, medicalRecord, patientId };
      commit("SET_FORM", value);
    },
    async updateMedicalRecords({ commit, dispatch }, { patientId, medicalRecord }) {
      try {
        commit("SET_FORM_ERROR", null);
        await medicalRecordsService.updateMedicalRecords(patientId, medicalRecord);
        dispatch("stopEditing");
        dispatch("getMedicalRecords", patientId);
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async stopEditing({ commit, dispatch }) {
      const value = { open: false, medicalRecord: null, patientId: null };
      commit("SET_FORM", value);
      dispatch("closeModal");
    },
  },
  mutations: {
    SET_MEDICALRECORDS(state, { patientId, medicalRecords }) {
      state.medicalRecords[patientId] = medicalRecords
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_MODAL(state, { open, patientId }) {
      state.modal.open = open;
      state.modal.patientId = patientId;
    },
    SET_FORM(state, { open, medicalRecord, patientId }) {
      state.form.open = open;
      state.form.patientId = patientId;
      state.form.medicalRecord = medicalRecord;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.medicalRecords = [];
      state.loading = false;
      state.form = {
        open: false,
        medicalRecord: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.medicalRecords.length;
    }
  }
}
