<template>
  <main class="mt-0 main-content main-content-bg">
    <section>
        <div class="container">
          <div class="row">
            <div class="mx-auto col-12 d-flex flex-column">
              <div class="card card-plain">
                <div class="card-body text-center">
                  <p class="mx-auto">
                    Obrigado!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
  </main>
  <patient-modal v-if="patientLoaded" :isExternalRegistration="true" @patient-added="startAddingTriage"/>
  <triage-modal v-if="triageLoaded" :isExternalRegistration="true" @onBack="startEditingPatient" @onFinishExternalRegistration="finish" />
</template>

<script>

import PatientModal from "./components/PatientModal.vue"
import TriageModal from "./components/TriageFormModal.vue"
import { mapMutations } from "vuex";
import { useRoute } from 'vue-router'
import showSwal from "/src/mixins/showSwal.js";

export default {
  // state: initialState,
  name: "ExternalRegistration",
  components: {
    PatientModal,
    TriageModal,
  },

  data() {
    return {
      loading: false,
      isTokenValid: false,
      patientLoaded: false,
      triageLoaded: false,
    };
  },
  created() {
    this.hideSidenav();
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  mounted() {
    const route = useRoute();
    const token = route.query.token;
    
    this.tokenValidate(token)
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig", "hideSidenav"]),

    async tokenValidate(token) {
      localStorage.setItem("urlToken", JSON.stringify(token));
      
      try {
        const result = await this.$store.dispatch('companies/tokenValidate');
        
        if (result && result.company_id) {
          this.isTokenValid = true;
          await this.startAddingPatient();
        } else {
          showSwal.methods.showSwal({
              type: "error",
              message: "Link inválido ou expirado.",
              timer: 5000
          });
          this.isTokenValid = false;
        }

      } catch (error) {
        showSwal.methods.showSwal({
          type: "error",
          message: "Link inválido ou expirado.",
          timer: 5000
        });
        this.isTokenValid = false;
      }
    },

    async startAddingPatient() {
      this.patientLoaded = true;
      await this.$nextTick();
      await this.$store.dispatch('patients/startAdding');
    },

    async startEditingPatient() {
      this.patientLoaded = true;
      await this.$nextTick();
      let patientData = JSON.parse(localStorage.getItem('patient'));
      await this.$store.dispatch('patients/startEditing', patientData);
    },

    async startAddingTriage() {
      this.triageLoaded = true;
      await this.$nextTick();
      await this.$store.dispatch('triages/startAdding');
    },

    finish() {
      localStorage.clear();
    },
  },
};
</script>
