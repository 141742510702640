<template>
  <div class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100" id="sidenav-collapse-main">
    <ul class="navbar-nav">
      <li v-for="({ name, icon }, index) in menuItems" :key="index">
        <sidenav-collapse :navText="name" :to="{ name: name }" :icon="icon">
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavCollapse from "./SidenavCollapse.vue";
import { routes } from "../../router/";

export default {
  name: "SidenavList",
  props: {
    cardBg: String,
  },
  data() {
    return {
      title: "Eyescan",
      controls: "dashboardsExamples",
      isActive: "active",
    };
  },
  computed: {
    menuItems() {
      const user = this.$store.state.auth.user;
      if (user?.type === 4) {
        return routes
          .filter(route => ['Dashboard', 'Empresas', 'Comercial'].includes(route.name))
          .map(route => ({
            name: route.name,
            icon: route.meta?.icon
          }));
      }

      return routes
        .filter(route => {
          if (!route.meta?.onMenu) return false;
          if (route.meta?.isAdmin && !this.$store.getters['auth/isAdmin']) return false;
          if (route.meta?.isAdminOrTech && !this.$store.getters['auth/isAdmin'] && !this.$store.getters['auth/isTech']) return false;
          if (route.meta?.isTech && !this.$store.getters['auth/isTech'] && !this.$store.getters['auth/isAdmin']) return false;
          return true;
        })
        .map(route => ({
          name: route.name,
          icon: route.meta?.icon
        }));
    }
  },
  components: {
    SidenavCollapse,
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
  },
};
</script>
