<template>
  <div class="py-4 container-fluid">
    <!-- Componente de Filtro -->
    <DashboardFilter />
    
    <!-- Componente DadosGerais -->
    <DadosGerais
      :colaboradores="dashboardData.total || 0"
      :triagens="triageStats.total_triages || 0"
      :triagensPorcentagem="triageStats.percentage || 0"
      :exames="examStats.total_exams || 0"
      :examesPorcentagem="examStats.percentage || 0"
      :triagemChartData="triagemChartData"
      :triagemChartOptions="triagemChartOptions"
      :exameChartData="exameChartData"
      :exameChartOptions="exameChartOptions"
    />

    <!-- Componente IndicadoresGerais -->
    <IndicadoresGerais
      :generoMasculino="{ 
        numero: genderStats.male?.count || 0, 
        percentual: genderStats.male?.percentage || 0 
      }"
      :generoFeminino="{ 
        numero: genderStats.female?.count || 0, 
        percentual: genderStats.female?.percentage || 0 
      }"
      :triagensHomens="{ numero: 250, porcentagem: 55.6 }"
      :triagensMulheres="{ numero: 180, porcentagem: 40 }"
      :triagensNaoIdentificados="{ numero: 20, porcentagem: 4.4 }"
      :faixaEtariaChartData="faixaEtariaChartData"
      :faixaEtariaChartOptions="faixaEtariaChartOptions"
      :condicoesSistemicasChartData="condicoesSistemicasChartData"
      :condicoesSistemicasChartOptions="condicoesSistemicasChartOptions"
      :examesGestantes="{ numero: 100 }"
      :examesTabagistas="{ numero: 50, porcentagem: 16.7 }"
      :examesUsamEPI="{ numero: 120 }"
      :usoTelaChartData="usoTelaChartData"
      :usoTelaChartOptions="usoTelaChartOptions"
      :faixaEtariaData="{
        labels: ['Até 20 anos', 'Entre 20 e 40 anos', 'Entre 40 e 60 anos', 'Acima de 60 anos'],
        datasets: [{
          data: [
            ageStats.age_groups?.under_20?.count || 0,
            ageStats.age_groups?.between_20_40?.count || 0,
            ageStats.age_groups?.between_40_60?.count || 0,
            ageStats.age_groups?.above_60?.count || 0
          ],
          backgroundColor: 'rgba(75, 192, 192, 0.6)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      }"
      :condicoesSistemicasData="{
        labels: getMainConditionsLabels(),
        datasets: [
          {
            label: 'Masculino',
            data: getMainConditionsByGender('male'),
            backgroundColor: 'rgba(54, 162, 235, 0.6)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          },
          {
            label: 'Feminino',
            data: getMainConditionsByGender('female'),
            backgroundColor: 'rgba(255, 99, 132, 0.6)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 1
          }
        ]
      }"
      :gestantes="{
        numero: healthHabitsStats.pregnancy?.count || 0,
        percentual: healthHabitsStats.pregnancy?.percentage || 0
      }"
      :lactantes="{
        numero: healthHabitsStats.breastfeeding?.count || 0,
        percentual: healthHabitsStats.breastfeeding?.percentage || 0
      }"
      :epi="{ numero: 999, percentual: 99.7 }"
      :tabagismo="{
        numero: healthHabitsStats.smoking_habits?.smokers?.count || 0,
        percentual: healthHabitsStats.smoking_habits?.smokers?.percentage || 0
      }"
      :usoTelaData="{
        labels: ['Nenhuma', '1-3 horas', '3-6 horas', '> 6 horas', 'Sem informação'],
        datasets: [{
          data: [
            screenTimeStats.screen_time_distribution?.['Nenhuma']?.count || 0,
            screenTimeStats.screen_time_distribution?.['1-3 horas']?.count || 0,
            screenTimeStats.screen_time_distribution?.['3-6 horas']?.count || 0,
            screenTimeStats.screen_time_distribution?.['> 6 horas']?.count || 0,
            screenTimeStats.no_information?.count || 0
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)'
          ],
          borderWidth: 1
        }]
      }"
    />

    <!-- Componente IndicadoresSaudeOcular -->
    <IndicadoresSaudeOcular
      :colaboradoresComQueixas="120"
      :colaboradoresComQueixasPorcentagem="20"
      :usuariosDeOculos="80"
      :usuariosDeOculosPorcentagem="13.3"
      :usuariosDeLentes="50"
      :usuariosDeLentesPorcentagem="8.3"
      :doencasChartData="doencasChartData"
      :acuidadeODChartData="acuidadeODChartData"
      :acuidadeOEChartData="acuidadeOEChartData"
      :condicoesOcularesChartData="condicoesOcularesChartData"
      :queixasData="{
        labels: Object.keys(eyeComplaintsStats.eye_complaints_statistics || {}),
        datasets: [{
          data: Object.values(eyeComplaintsStats.eye_complaints_statistics || {}).map(stat => stat.total),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)'
          ],
          borderWidth: 1
        }]
      }"
      :doencasData="{
        labels: Object.keys(eyeDiseasesStats.eye_diseases_statistics || {}),
        datasets: [{
          data: Object.values(eyeDiseasesStats.eye_diseases_statistics || {}).map(stat => stat.total),
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)',
            'rgba(75, 192, 192, 0.6)',
            'rgba(153, 102, 255, 0.6)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(153, 102, 255, 1)'
          ],
          borderWidth: 1
        }]
      }"
      :oculos="{
        numero: visionAidsStats.glasses_users?.total || 0,
        percentual: visionAidsStats.glasses_users?.total_percentage || 0
      }"
      :lentes="{
        numero: visionAidsStats.contact_lens_users?.total || 0,
        percentual: visionAidsStats.contact_lens_users?.total_percentage || 0
      }"
      :cirurgiasData="{
        labels: ['Sim', 'Não'],
        datasets: [{
          data: [
            eyeSurgeriesStats.with_surgeries?.total || 0,
            eyeSurgeriesStats.without_surgeries?.total || 0
          ],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)'
          ],
          borderWidth: 1
        }]
      }"
      :acuidade="{
        normal: {
          numero: visionQualityStats.vision_quality_statistics?.normal_vision?.count || 0,
          percentual: visionQualityStats.vision_quality_statistics?.normal_vision?.percentage || 0
        },
        baixa: {
          numero: visionQualityStats.vision_quality_statistics?.low_vision?.count || 0,
          percentual: visionQualityStats.vision_quality_statistics?.low_vision?.percentage || 0
        },
        severa: {
          numero: visionQualityStats.vision_quality_statistics?.severe_low_vision?.count || 0,
          percentual: visionQualityStats.vision_quality_statistics?.severe_low_vision?.percentage || 0
        }
      }"
      :autorrefratorData="{
        labels: ['Miopia', 'Hipermetropia', 'Astigmatismo'],
        datasets: [{
          data: [350, 280, 370],
          backgroundColor: [
            'rgba(255, 99, 132, 0.6)',
            'rgba(54, 162, 235, 0.6)',
            'rgba(255, 206, 86, 0.6)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)'
          ],
          borderWidth: 1
        }]
      }"
      :retinografia="{
        numero: retinographyStats.with_retinography?.total || 0,
        percentual: retinographyStats.with_retinography?.total_percentage || 0
      }"
    />
  </div>
</template>

<script>
import DashboardFilter from "./components/DashboardFilter.vue";
import DadosGerais from "./components/DadosGerais.vue";
import IndicadoresGerais from "./components/IndicadoresGerais.vue";
import IndicadoresSaudeOcular from "./components/IndicadoresSaudeOcular.vue";
import { mapState } from 'vuex';

export default {
  name: "Dashboard",
  components: {
    DashboardFilter,
    DadosGerais,
    IndicadoresGerais,
    IndicadoresSaudeOcular
  },
  data() {
    return {
      triagemChartData: {
        labels: ["Outubro", "Novembro", "Dezembro"],
        datasets: [{
          label: "Triagens Realizadas",
          data: [120, 150, 180],
          backgroundColor: "rgba(75, 192, 192, 0.6)",
        }]
      },
      triagemChartOptions: {
        responsive: true,
        plugins: { legend: { display: true } }
      },
      exameChartData: {
        labels: ["Outubro", "Novembro", "Dezembro"],
        datasets: [{
          label: "Exames Realizados",
          data: [80, 100, 120],
          backgroundColor: "rgba(255, 99, 132, 0.6)",
        }]
      },
      exameChartOptions: {
        responsive: true,
        plugins: { legend: { display: true } }
      },
      faixaEtariaChartData: {
        labels: ["Até 20 anos", "20-40 anos", "40-60 anos", "+60 anos"],
        datasets: [{
          label: "Faixa Etária",
          data: [50, 200, 150, 100],
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
          ],
        }]
      },
      faixaEtariaChartOptions: {
        responsive: true,
        plugins: { legend: { display: true } }
      },
      condicoesSistemicasChartData: {
        labels: ["Diabetes", "Hipertensão", "Artrite", "Tireoide", "Outros"],
        datasets: [{
          data: [30, 50, 20, 15, 10],
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
            "rgba(75, 192, 192, 0.6)",
            "rgba(153, 102, 255, 0.6)",
          ],
        }]
      },
      condicoesSistemicasChartOptions: {
        responsive: true,
        plugins: { legend: { display: true } }
      },
      usoTelaChartData: {
        labels: ["Nenhum uso", "1-3 horas", "3-6 horas", "+6 horas"],
        datasets: [{
          data: [20, 80, 120, 100],
          backgroundColor: [
            "rgba(255, 159, 64, 0.6)",
            "rgba(153, 102, 255, 0.6)",
            "rgba(255, 205, 86, 0.6)",
            "rgba(54, 162, 235, 0.6)",
          ],
        }]
      },
      usoTelaChartOptions: {
        responsive: true,
        plugins: { legend: { display: true } }
      },
      doencasChartData: {
        labels: ["Glaucoma", "Ceratocone", "DMRI", "Trauma", "Outros"],
        datasets: [{
          data: [20, 15, 10, 5, 3],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0", "#9966FF"],
        }]
      },
      acuidadeODChartData: {
        labels: ["Normal", "Baixa", "Severa"],
        datasets: [{
          data: [80, 15, 5],
          backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
        }]
      },
      acuidadeOEChartData: {
        labels: ["Normal", "Baixa", "Severa"],
        datasets: [{
          data: [75, 20, 5],
          backgroundColor: ["#36A2EB", "#FF6384", "#FFCE56"],
        }]
      },
      condicoesOcularesChartData: {
        labels: ["Miopia", "Hipermetropia", "Astigmatismo"],
        datasets: [{
          data: [40, 30, 30],
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        }]
      }
    };
  },
  computed: {
    ...mapState('dashboard', {
      dashboardData: state => state.dashboardData || { total: 0 },
      triageStats: state => state.triageStats || { 
        total_triages: 0, 
        percentage: 0 
      },
      examStats: state => state.examStats || {
        total_exams: 0,
        percentage: 0
      },
      genderStats: state => state.genderStats || {
        male: { count: 0, percentage: 0 },
        female: { count: 0, percentage: 0 }
      },
      ageStats: state => state.ageStats || {
        age_groups: {
          under_20: { count: 0, percentage: 0 },
          between_20_40: { count: 0, percentage: 0 },
          between_40_60: { count: 0, percentage: 0 },
          above_60: { count: 0, percentage: 0 }
        }
      },
      conditionsStats: state => state.conditionsStats,
      healthHabitsStats: state => state.healthHabitsStats || {
        pregnancy: { count: 0, percentage: 0 },
        breastfeeding: { count: 0, percentage: 0 },
        smoking_habits: {
          smokers: { count: 0, percentage: 0 },
          non_smokers: { count: 0, percentage: 0 }
        }
      },
      screenTimeStats: state => state.screenTimeStats || {
        total_patients_with_triage: 0,
        screen_time_distribution: {},
        no_information: { count: 0, percentage: 0 }
      },
      eyeComplaintsStats: state => state.eyeComplaintsStats || {
        total_patients_with_triage: 0,
        eye_complaints_statistics: {}
      },
      eyeDiseasesStats: state => state.eyeDiseasesStats || {
        total_patients_with_triage: 0,
        eye_diseases_statistics: {}
      },
      visionAidsStats: state => state.visionAidsStats || {
        total_patients_with_triage: 0,
        glasses_users: { total: 0, total_percentage: 0 },
        contact_lens_users: { total: 0, total_percentage: 0 },
        both_users: { total: 0, total_percentage: 0 }
      },
      eyeSurgeriesStats: state => state.eyeSurgeriesStats || {
        total_patients_with_triage: 0,
        with_surgeries: { total: 0, total_percentage: 0 },
        without_surgeries: { total: 0, total_percentage: 0 }
      },
      visionQualityStats: state => state.visionQualityStats || {
        total_patients_with_exam: 0,
        total_eyes_examined: 0,
        vision_quality_statistics: {
          normal_vision: { count: 0, percentage: 0 },
          low_vision: { count: 0, percentage: 0 },
          severe_low_vision: { count: 0, percentage: 0 }
        }
      },
      retinographyStats: state => state.retinographyStats || {
        total_patients_with_exam: 0,
        total_retinography_exams: 0,
        with_retinography: { total: 0, total_percentage: 0 },
        without_retinography: { total: 0, total_percentage: 0 }
      }
    }),
    
    getMainConditionsLabels() {
      return () => {
        const mainConditions = [
          'Diabetes Melitus',
          'Hipertensão Arterial Sistêmica',
          'Artrite Reumatóide',
          'Doenças Tireoidianas',
          'Outros'
        ];
        return mainConditions;
      }
    },
    
    getMainConditionsByGender() {
      return (gender) => {
        const mainConditions = this.getMainConditionsLabels();
        return mainConditions.map(condition => {
          if (condition === 'Outros') {
            const otherConditions = Object.entries(this.conditionsStats || {})
              .filter(([key]) => !mainConditions.includes(key));
            return otherConditions.reduce((sum, [, stats]) => 
              sum + (stats.by_gender[gender]?.count || 0), 0);
          }
          return this.conditionsStats[condition]?.by_gender[gender]?.count || 0;
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch('dashboard/getPatientsCount');
    this.$store.dispatch('dashboard/getTriageStatistics');
    this.$store.dispatch('dashboard/getExamStatistics');
    this.$store.dispatch('dashboard/getGenderStatistics');
    this.$store.dispatch('dashboard/getAgeStatistics');
    this.$store.dispatch('dashboard/getConditionsStatistics');
    this.$store.dispatch('dashboard/getHealthHabitsStatistics');
    this.$store.dispatch('dashboard/getScreenTimeStatistics');
    this.$store.dispatch('dashboard/getEyeComplaintsStatistics');
    this.$store.dispatch('dashboard/getEyeDiseasesStatistics');
    this.$store.dispatch('dashboard/getVisionAidsStatistics');
    this.$store.dispatch('dashboard/getEyeSurgeriesStatistics');
    this.$store.dispatch('dashboard/getVisionQualityStatistics');
    this.$store.dispatch('dashboard/getRetinographyStatistics');
  }
};
</script>

<style>
.container-fluid {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}
</style>
