<template>
  <div id="new_form_modal" class="" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Retinografia</h5>
        </div>
        <div class="modal-body">
          <Form id="Retinography" ref="formRef" role="form" class="text-start" @submit="handleSubmit">
            <div class="mb-3">
              <label for="name" class="form-label">Nome</label>
              <input type="text" class="form-control" id="name" v-model="formData.name" required />
            </div>
            <div class="mb-3">
              <label for="retinography_img" class="form-label">Arquivo</label>
              <soft-file-upload
                id="retinography_img"
                name="retinography_img"
                v-model="formData.retinography_img"
                :initialValue="null"
              />
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <div> </div>
          <button type="submit" form="Retinography" class="btn btn-success">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { Form } from 'vee-validate';
import showSwal from "/src/mixins/showSwal.js";
import SoftFileUpload from '../components/SoftFileUpload.vue';

export default {
  name: 'Retinography',
  components: {
    SoftFileUpload,
    Form
  },
  setup() {
    const store = useStore();
    const formData = ref({
      name: '',
      retinography_img: null
    });
    const formRef = ref(null);

    const handleSubmit = async () => {
      try {
        await store.dispatch('exams/addRetinography', { retinography: { file_name: formData.value.name, retinography_img: formData.value.retinography_img } });
        showSwal.methods.showSwal({
          type: "success",
          message: "Salvo com sucesso!",
        });
        formRef.value.resetForm();
        formData.value.name = '';
        formData.value.retinography_img = null;
      } catch (error) {
        console.error(error);
        showSwal.methods.showSwal({
          type: "error",
          message: error.message,
        });
      }
    };

    const onClose = () => {
      // Lógica para fechar o modal
      console.log('Modal fechado');
    };

    return {
      formData,
      formRef,
      handleSubmit,
      onClose
    };
  }
};
</script>

<style scoped>
/* Adicione estilos personalizados aqui, se necessário */
</style>