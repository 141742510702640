import api from "./axios";
import VueJwtDecode from 'vue-jwt-decode'

export default {
  async login(user) {
    const response = await api.post("/login", user);
    const token = response.data.access_token;

    if (token) {
      localStorage.setItem("userF", JSON.stringify(token));

      const decodedToken = VueJwtDecode.decode(token);
      const userId = decodedToken.sub;

      const userData = await this.getCurrentUser(userId);
      localStorage.setItem("userData", JSON.stringify(userData));
      
      return {
        token,
        user: userData
      };
    }
  },

  async getCurrentUser(userId) {
    try {
      if (!userId) {
        const token = JSON.parse(localStorage.getItem('userF'));
        if (token) {
          const decodedToken = VueJwtDecode.decode(token);
          userId = decodedToken.sub;
        }
      }

      if (!userId) {
        throw new Error('User ID not found');
      }

      const response = await api.get(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  },

  async logout() {
    try {
      await api.post("/logout");
    } finally {
      localStorage.removeItem("userF");
    }
  },

  async register(user) {
    const response = await api.post("/register", user);
    if (response.data.access_token) {
      localStorage.setItem("userF", JSON.stringify(response.data.access_token));
    }
  },

  parseToken() {
    try {
      const token = JSON.parse(localStorage.getItem('userF'));
      const userData = JSON.parse(localStorage.getItem('userData'));
      const data = VueJwtDecode.decode(token);

      if (!userData && token) {
        this.getCurrentUser(data.sub).then(user => {
          if (user) {
            localStorage.setItem("userData", JSON.stringify(user));
          }
        });
      }

      return {
        loggedIn: true,
        isAdmin: data.scopes.includes('admin'),
        isTech: data.scopes.includes('tecnico'),
        readOnly: data.scopes.includes('read-only'),
        user: userData
      };
    } catch (error) {
      return {
        loggedIn: false,
        isAdmin: null,
        isTech: null,
        readOnly: null,
        user: null
      }
    }
  },
  getToken(){
    try {
      const token = JSON.parse(localStorage.getItem('userF'));

      if (!token) {
        return JSON.parse(localStorage.getItem('urlToken'));
      }

      return token;
    } catch (error) {
      return null;
    }
  }
};