<template>
  <div class="modal fade" id="supports_modal" tabindex="-1" data-bs-keyboard="false">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <Form
            id="myForm"
            ref="form"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <label>Quais equipamentos serão utilizados?<span class="required">*</span></label>
            
            <div v-for="(equipament, index) in state.equipaments" :key="index" class="mb-2">
              <soft-select-search
                :id="'equipament_id_' + index"
                :name="'equipament_id_' + index"
                v-model="equipament.id"
                :options="EquipamentsTypesOptions"
                placeholder="Selecione o equipamento"
              />
            </div>

            <div class="d-flex mt-2">
              <button type="button" @click="addEquipament" class="btn btn-success btn-sm">+</button>
              <button v-if="state.equipaments.length > 1" type="button" @click="removeEquipament" class="btn btn-danger btn-sm ms-2">-</button>
            </div>

            <label>Para qual empresa será prestado o serviço?<span class="required">*</span></label>
            <soft-select-search
              id="company_id"
              name="company_id"
              v-model="state.company_id"
              :options="companies"
              placeholder="Selecione a empresa"
              required
            />

            <label>Qual técnico prestará o serviço?<span class="required">*</span></label>
            <soft-select-search
              id="user_id"
              name="user_id"
              v-model="state.user_id"
              :options="users"
              placeholder="Selecione o técnico"
              required
            />

            <label for="date">Data do Atendimento<span class="required">*</span></label>
            <soft-date-time-picker
              id="date"
              v-model="state.date"
              placeholder="Selecione a Data"
              name="supportcall"
              format="dd/MM/yyyy"
              model-type="yyyy-MM-dd"
              :enable-time-picker="false"
              required
            />

            <label for="supportobs">Observações</label>
            <div class="form-group">
              <textarea
                class="form-control"
                id="observacoes"
                v-model="state.observacoes"
                rows="4"
                maxlength="500"
                placeholder="Observações Gerais"
                name="observacoes"
                style="resize: vertical; min-height: 100px;"
              ></textarea>
              <small class="text-muted">
                {{ state.observacoes ? state.observacoes.length : 0 }}/500 caracteres
              </small>
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="stopEditing">Cancelar</button>
          <button type="submit" form="myForm" class="btn btn-success" :disabled="loading">
            <span v-if="loading" class="spinner-border spinner-border-sm"></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import "vue-search-select/dist/VueSearchSelect.css";
import { mapState } from "vuex";
import SoftDateTimePicker from "../../components/SoftDateTimePicker.vue";
import formModalMixin from "../../mixins/form-modal-mixin";
import showSwal from "../../mixins/showSwal";
import { Form } from "vee-validate";
import { EquipamentsTypesOptions } from "../../constants/EquipamentsTypes";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";

const getState = (supports = {}) => {
  
  let equipamentsList = [];
  if (supports.equipaments) {
    try {
      const equipaments = Array.isArray(supports.equipaments) 
        ? supports.equipaments 
        : typeof supports.equipaments === 'string'
          ? JSON.parse(supports.equipaments)
          : [];

      equipamentsList = equipaments.map(id => ({ id: String(id) }));
    } catch (e) {
      console.error('Error parsing equipaments:', e);
      equipamentsList = [{ id: '' }];
    }
  } else {
    equipamentsList = [{ id: '' }];
  }

  return {
    id: supports?.id || null,
    equipaments: equipamentsList,
    user_id: supports?.user_id || '',
    company_id: supports?.company_id || '',
    date: supports?.data_atendimento || '',
    observacoes: supports?.observacoes || '',
  };
};

export default {
  name: "SupportsModal",
  mixins: [formModalMixin('supports')],
  components: {
    Form,
    SoftSelectSearch,
    SoftDateTimePicker
  },
  mounted() {
    this.supports_modal = new bootstrap.Modal('#supports_modal', {
      backdrop: 'static'
    });
  },
  data() {
    return {
      EquipamentsTypesOptions,
      loading: false,
      state: getState(this.supports || {}),
    };
  },
  computed: {
    ...mapState('supports', {
      open: state => state.form.open,
      supports: state => state.form.supports,
      title: state => state.form.supports?.id ? "Atualizar Atendimento" : "Novo Atendimento"
    }),
    companies() {
      return this.$store.state.companies.options;
    },
    users() {
      const allUsers = this.$store.state.users.users;
      const activeType3Users = allUsers.filter(user => user.type === 3 && user.active === 1);
      return activeType3Users.map(user => ({
        label: user.name,
        value: user.id
      }));
    }
  },
  watch: {
    supports: {
      immediate: true,
      handler(newSupports) {
        if (newSupports) {
          this.$nextTick(() => {
            this.state = getState({
              ...newSupports,
              equipaments: Array.isArray(newSupports.equipaments) 
                ? newSupports.equipaments 
                : JSON.parse(newSupports.equipaments || '[]')
            });
          });
        }
      },
      deep: true
    },
    open(newOpen) {
      if (newOpen) {
        this.resetForm();
        this.openSupportsModal();
      } else {
        this.closeSupportsModal();
      }
    }
  },
  async created() {
    try {
      await this.$store.dispatch("companies/getOptions");
      await this.$store.dispatch("users/getUsers");
    } catch (error) {
      console.error("Erro ao carregar empresas:", error);
    }
  },
  methods: {
    addEquipament() {
      this.state.equipaments.push({ id: '' });
    },
    removeEquipament() {
      if (this.state.equipaments.length > 1) {
        this.state.equipaments.pop();
      }
    },
    
  async handleSubmit() {
      this.loading = true;
      try {
        const filteredEquipaments = this.state.equipaments
          .filter(equip => equip.id)
          .map(equip => equip.id);

        const supportsData = {
          equipaments: filteredEquipaments,
          user_id: this.state.user_id,
          company_id: this.state.company_id,
          data_atendimento: this.state.date,
          observacoes: this.state.observacoes,
        };

        let result;
        if (this.state.id) {
          result = await this.$store.dispatch('supports/updateSupport', { id: this.state.id, ...supportsData });
        } else {
          result = await this.$store.dispatch('supports/addSupport', supportsData);
        }

        if (result) {
          await this.$store.dispatch('supports/getSupports');
          this.stopEditing();
          showSwal.methods.showSwal({
            type: "success",
            message: "Atendimento salvo com sucesso!",
            timer: 5000
          });
        }
      } catch (error) {
        console.error("Erro ao salvar o atendimento:", error);
        showSwal.methods.showSwal({
          type: "error",
          message: "Erro ao salvar o atendimento. Tente novamente mais tarde.",
          timer: 5000
        });
      } finally {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('supports/stopEditing');
    },
    openSupportsModal() {
      this.supports_modal.show();
    },
    closeSupportsModal() {
      this.supports_modal.hide();
    },
    resetForm() {
      if (this.supports) {
        this.state = getState(this.supports);
      } else {
        this.state = getState({});
      }
      if (this.$refs.form) {
        this.$refs.form.resetForm();
      }
    },
  }
}
</script>

<style scoped>
.form-control {
  padding: 0.5rem;
  line-height: 1.5;
}

textarea.form-control {
  min-height: 100px;
  max-height: 300px;
}

.text-muted {
  display: block;
  text-align: right;
  margin-top: 0.25rem;
}
</style>
