<template>
  <tr @click="startEditing">
    <td>{{ support.user_name }}</td>
    <td>{{ support.company_name }}</td>
    <td>{{ formattedDate }}</td>
  </tr>
</template>

<script>
import moment from 'moment';

export default {
  name: 'SupportTile',
  props: ['support'],
  computed: {
    formattedDate() {
      return moment(this.support.data_atendimento).format("DD/MM/YYYY");
    }
  },
  methods: {
    startEditing() {
      this.$store.dispatch('supports/startEditing', this.support);
    },
  },
};
</script>