import CompanyTypes from "../constants/CompanyTypes";
import companiesService from "../services/companies.service"
import { parseFormError } from "../services/utils";

const initialState = {
  companies: [],
  options:[],
  clinics: [],
  loading: false,
  page: 1,
  lastPage: 1,
  perPage: 10,
  total: 0,
  query: "", 
  form: {
    open: false,
    company: null,
    error: null
  }
}

export const companies = {
  namespaced: true,
  state: initialState,
  actions: {
    async getCompanies({ commit, state }) {
      commit("SET_LOADING", true);
      const response = await companiesService.getCompanies(state.page, state.perPage);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page)
    },
    async exportAllCompanies({ commit }) {
      commit("SET_LOADING", true);

      try {
        const response = await companiesService.exportAllCompanies();

        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'empresas.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        commit("SET_LOADING", false);
      } catch (error) {
        commit("SET_LOADING", false);
        console.error("Erro ao baixar as empresas:", error);
      }
    },
    async findCompanies({ commit, state }, query) {
      commit("SET_QUERY", query);
      commit("SET_LOADING", true);
      const response = await companiesService.findCompanies(query, state.page, state.perPage);
      commit("SET_USERS", response.data);
      commit("SET_PAGE", response.current_page);
      commit("SET_TOTAL", response.total);
      commit("SET_PER_PAGE", response.per_page);
      commit("SET_LAST_PAGE", response.last_page);
    },
    async getOptions({commit}){
      commit("SET_LOADING", true);
      const response = await companiesService.getOptions();
      commit("SET_OPTIONS", response);
    },
    async getClinics({commit}){
      commit("SET_LOADING", true);
      const response = await companiesService.getOptions(CompanyTypes.CLINIC);
      commit("SET_CLINICS", response);
    },
    changeToPage({commit, dispatch, state}, page){
      if (page >= 1 && page <= state.lastPage){
        commit("SET_PAGE", page);
        dispatch("getCompanies");
      } 
    },
    
    changePerPage({commit, dispatch}, perPage){
      commit("SET_PER_PAGE", perPage);
      dispatch("getCompanies")

    },
  
    startAdding({ commit }) {
      const value = { open: true, company: null };
      commit("SET_FORM", value);
    },
    async addCompany({ commit, dispatch }, company) {
      try {
        commit("SET_FORM_ERROR", null);
        await companiesService.addCompany(company);
        dispatch("stopEditing");
        dispatch("getCompanies");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    async startEditing({ commit }, company) {
      const value = { open: true, company: company };
      commit("SET_FORM", value);
    },
    async updateCompany({ commit, dispatch }, company) {
      try {
        commit("SET_FORM_ERROR", null);
        await companiesService.updateCompany(company);
        dispatch("stopEditing");
        dispatch("getCompanies");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error))
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, company: null };
      commit("SET_FORM", value);
    },
    async tokenGenerate({ commit }, company_id) {
      commit
      try {
        const token = await companiesService.tokenGenerate(company_id);
        return token;
      } catch (error) {
        return;
      }
    },
    async tokenValidate() {
      const response = await companiesService.tokenValidate();
      if (response?.message === 'Token válido') {
        return response;
      }
      throw new Error('Token inválido');
    },

  },
  mutations: {
    SET_USERS(state, companies) {
      state.companies = companies
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_QUERY(state, query) {
      state.query = query;
    },
    SET_PAGE(state, page) {
      state.page = page
    },
    SET_CLINICS(state, clinics){
      state.clinics = clinics
    },
    SET_TOTAL(state, total) {
      state.total = total
    },
    SET_PER_PAGE(state, per_page) {
      state.perPage = per_page
    },
    SET_LAST_PAGE(state, last_page) {
      state.lastPage = last_page
    },

    SET_FORM(state, { open, company }) {
      state.form.open = open;
      state.form.company = company;
      state.form.error = null;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },

    SET_OPTIONS(state, companies){
      state.options = companies
      state.loading = false
    },
    resetState(state) {
      state.companies = [];
      state.loading = false;
      state.form = {
        open: false,
        company: null,
      };
    }
  },
  getters: {
    options(state) {
      return state.companies.map(company => ({
        label: company.corporate_reason,
        value: company.id
      }))
    },
    clinics(state) {
      return state.companies.filter(company => 
        company.type === CompanyTypes.CLINIC
      )
    },
    clinicOptions(state, getters) {
      return getters.clinics.map(company => ({
        label: company.corporate_reason,
        value: company.id
      }))
    }
  }
}
