export const EyeComplaints = {
    ZERO: "Nenhum",
    LOW_VISION: "Dificuldade de enxergar de longe",
    HEADACHE: "Dor de cabeça",
    RED_EYE: "Olho vermelho",
    DRY_EYE: "Olho seco",
    TEARING: "Lacrimejamento",
    ITCHING: "Coceira nos olhos",
    BURNING: "Ardencia nos olhos",
    FLASH_EYE: "Flashes de luz/ou pontos flutuanes na visão",
    OTHERS: "Outros",
};

export default EyeComplaints;

export const EyeComplaintsOptions = Object.values(EyeComplaints).map(value => ({
    value: value,
    label: value,
}));
