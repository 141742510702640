<template>
  <div class="py-4 container-fluid text-center">
    <h1 class="text-primary mb-4">INDICADORES DE SAÚDE OCULAR</h1>

    <div class="row">
      <!-- Queixas Oftalmológicas -->
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body d-flex flex-column">
            <h3 class="text-center text-secondary mb-3">Queixas Oftalmológicas</h3>
            <div class="chart-container flex-grow-1">
              <PieChart :data="queixasData" :options="chartOptions" />
            </div>
          </div>
        </div>
      </div>

      <!-- Doenças Oculares -->
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body d-flex flex-column">
            <h3 class="text-center text-secondary mb-3">Doenças Oculares Atuais ou Prévias</h3>
            <div class="chart-container flex-grow-1">
              <DoughnutChart :data="doencasData" :options="chartOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Uso de Correção Visual e Cirurgias Oculares -->
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Uso de Correção Visual</h3>
            <div class="row justify-content-center">
              <div class="col-md-5">
                <div class="indicator-box">
                  <h5 class="text-muted">Óculos</h5>
                  <h4 class="text-primary">{{ oculos.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ oculos.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-5">
                <div class="indicator-box">
                  <h5 class="text-muted">Lentes de Contato</h5>
                  <h4 class="text-primary">{{ lentes.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ lentes.percentual }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-3">Cirurgias Oculares Prévias</h3>
            <div style="max-width: 300px; margin: 0 auto;">
              <DoughnutChart :data="cirurgiasData" :options="chartOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Acuidade Visual -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body card-body-vision">
            <h3 class="text-center text-secondary mb-3">Acuidade Visual</h3>
            <div class="row justify-content-center">
              <div class="col-md-4">
                <div class="indicator-box">
                  <h5 class="text-muted">Visão Normal</h5>
                  <h4 class="text-primary">{{ acuidade.normal.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ acuidade.normal.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="indicator-box">
                  <h5 class="text-muted">Baixa Visão</h5>
                  <h4 class="text-primary">{{ acuidade.baixa.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ acuidade.baixa.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-4">
                <div class="indicator-box">
                  <h5 class="text-muted">Baixa Visão Severa</h5>
                  <h4 class="text-primary">{{ acuidade.severa.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ acuidade.severa.percentual }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Autorrefrator -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-3">Autorrefrator</h3>
            <div style="max-width: 300px; margin: 0 auto;">
              <DoughnutChart :data="autorrefratorData" :options="chartOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Retinografia -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body card-body-retinografia">
            <h3 class="text-center text-secondary mb-3">Retinografia</h3>
            <div class="indicator-box">
              <h4 class="text-primary">{{ retinografia.numero.toLocaleString('pt-BR') }}</h4>
              <p class="text-secondary">{{ retinografia.percentual }}%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from "../../components/PieChart.vue";
import DoughnutChart from "../../components/DoughnutChart.vue";

export default {
  name: "IndicadoresSaudeOcular",
  components: {
    PieChart,
    DoughnutChart
  },
  props: {
    queixasData: {
      type: Object,
      required: true
    },
    doencasData: {
      type: Object,
      required: true
    },
    oculos: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    lentes: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    cirurgiasData: {
      type: Object,
      required: true
    },
    acuidade: {
      type: Object,
      required: true,
      validator: prop => {
        return ['normal', 'baixa', 'severa'].every(key => 
          prop[key] && 'numero' in prop[key] && 'percentual' in prop[key]
        );
      }
    },
    autorrefratorData: {
      type: Object,
      required: true
    },
    retinografia: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          }
        }
      }
    };
  }
};
</script>

<style scoped>
.container-fluid {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.card {
  border-radius: 10px;
  background-color: white;
}

.card-body {
  height: 400px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.card-body-vision {
  height: 250px;
}

.card-body-retinografia {
  height: 200px;
}

.card-body p {
  font-size: 1rem;
  color: #6c757d;
}

.chart-container {
  position: relative;
  min-height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-body {
  padding: 1.5rem;
}

.indicator-box {
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 15px;
}

.indicator-box h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
}

.indicator-box p {
  margin: 0;
  font-size: 0.9rem;
}
</style>
