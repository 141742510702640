<template>
  <div 
    id="exam_form_modal"
    class="modal fade"
    tabindex="-1"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modal_demo_label">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <Form
            id="examForm"
            ref="examForm"
            role="form"
            class="text-start"
            :validation-schema="schema"
            @submit="handleSubmit"
          >
            <h4>Acuidade Visual</h4>

            <label for="name">Olho direito</label>
            <soft-select-search
              id="right_eye"
              name="right_eye"
              v-model="state.right_eye"
              :options="VisualAcuityOptions"
              placeholder="Olho direito"
              style="width: 100%;"
            />
            
            <label for="name">Olho esquerdo</label>
            <soft-select-search
              id="left_eye"
              name="left_eye"
              v-model="state.left_eye"
              :options="VisualAcuityOptions"
              placeholder="Olho esquerdo"
              style="width: 100%;"
            />

            <label for="name">Distúrbios Refrativos</label>
            <soft-select-search
              id="refractive_disorder"
              name="refractive_disorder"
              v-model="state.refractive_disorder"
              :options="RefractiveDisordersOptions"
              placeholder="Distúrbios Refrativos"
              style="width: 100%;"
            />

            <div class="d-flex flex-column align-items-center gap-5 mt-5">
              <div class="d-flex flex-column align-items-center gap-2">
                <h4>Refração</h4>
                <div class="d-flex">
                  <div class="form-check me-3">
                    <input 
                      class="form-check-input" 
                      type="radio" 
                      id="anexar" 
                      value="anexar" 
                      v-model="state.refraction_option"
                    >
                    <label class="form-check-label" for="anexar">
                      Anexar
                    </label>
                  </div>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="radio" 
                      id="importar" 
                      value="importar" 
                      v-model="state.refraction_option"
                    >
                    <label class="form-check-label" for="importar">
                      Importar
                    </label>
                  </div>
                </div>
                <soft-file-upload 
                  v-if="state.refraction_option === 'anexar'" 
                  id="portable_auto_refractor_img"
                  name="portable_auto_refractor_img"
                  :initialValue="state.portable_auto_refractor"
                  v-model="state.portable_auto_refractor_img"
                />
                <soft-select-search 
                  v-if="state.refraction_option === 'importar'" 
                  v-model="state.refraction_file"
                  :options="refractionFiles"
                  placeholder="Selecione um arquivo de refração"
                  @change="fetchRefractionFile"
                  style="width: 100%;"
                />
              </div>
              
              <div class="d-flex flex-column align-items-center gap-2">
                <h4>Retinografia</h4>
                <div class="d-flex">
                  <div class="form-check me-3">
                    <input 
                      class="form-check-input" 
                      type="radio" 
                      id="retinografia_anexar" 
                      value="anexar" 
                      v-model="state.retinography_option"
                    >
                    <label class="form-check-label" for="retinografia_anexar">
                      Anexar
                    </label>
                  </div>
                  <div class="form-check">
                    <input 
                      class="form-check-input" 
                      type="radio" 
                      id="retinografia_importar" 
                      value="importar" 
                      v-model="state.retinography_option"
                    >
                    <label class="form-check-label" for="retinografia_importar">
                      Importar
                    </label>
                  </div>
                </div>
                <soft-file-upload 
                  v-if="state.retinography_option === 'anexar'" 
                  id="retinography_img"
                  name="retinography_img"
                  :initialValue="state.retinography"
                  v-model="state.retinography_img"
                />
                <soft-select-search 
                  v-if="state.retinography_option === 'importar'" 
                  v-model="state.retinography_file"
                  :options="retinographyFiles"
                  placeholder="Selecione um arquivo de retinografia"
                  style="width: 100%;"
                />
              </div>
            </div>

            <div class="mt-5">
              <h5 class="text-center">Resultado da Triagem Oftalmológica</h5>
              <p class="text-center">Selecione a classificação final do paciente:</p>

              <div class="form-check">
                <input 
                  class="form-check-input" 
                  type="radio" 
                  id="liberado" 
                  value="liberado" 
                  v-model="state.final_classification"
                >
                <label class="form-check-label" for="liberado">
                  Paciente sem queixa - Liberado
                </label>
              </div>

              <div class="form-check">
                <input 
                  class="form-check-input" 
                  type="radio" 
                  id="consulta" 
                  value="consulta" 
                  v-model="state.final_classification"
                >
                <label class="form-check-label" for="consulta">
                  Paciente com queixa leve - Encaminhado para consulta oftalmológica de rotina
                </label>
              </div>

              <div class="form-check">
                <input 
                  class="form-check-input" 
                  type="radio" 
                  id="especialista" 
                  value="especialista" 
                  v-model="state.final_classification"
                >
                <label class="form-check-label" for="especialista">
                  Paciente com queixa significativa - Encaminhamento especializado (Encaminhar para especialista)
                </label>
              </div>

              <div v-if="state.final_classification === 'especialista'" class="mt-3">
                <p class="text-center">Selecione a especialidade indicada:</p>
                <div 
                  v-for="(option, index) in specialistOptions" 
                  :key="index" 
                  class="form-check"
                >
                  <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :id="'specialty-' + index" 
                    :value="option" 
                    v-model="state.specialist_specialties"
                  >
                  <label class="form-check-label" :for="'specialty-' + index">
                    {{ option }}
                  </label>
                </div>
              </div>
            </div>
          </Form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            @click="onClose"
          >Cancelar</button>
          <button
            type="submit"
            form="examForm"
            class="btn btn-success"
            :disabled="loading ? true : false"
          >
            <span
              v-if="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-else>Salvar</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap";
import { mapActions, mapState } from "vuex/dist/vuex.cjs.js";
import formModalMixin from "../../mixins/form-modal-mixin";
import SoftFileUpload from "../../components/SoftFileUpload.vue";
import { VisualAcuityOptions, RefractiveDisordersOptions } from "../../constants/VisualAcuity";
import SoftSelectSearch from "../../components/SoftSelectSearch.vue";
import * as yup from 'yup'
import { Form } from "vee-validate";
import equipamentService from "../../services/equipaments.service";

function getState(exam = null) {
  return {
    id: exam?.id,
    right_eye: exam?.right_eye ?? '',
    left_eye: exam?.left_eye ?? '',
    refractive_disorder: exam?.retroactive_disorder ?? '',
    portable_auto_refractor: exam?.portable_auto_refractor_img ?? '',
    retinography: exam?.retinography_img ?? '',
    portable_auto_refractor_img: null,
    retinography_img: null,
    final_classification: exam?.final_classification ?? '',
    specialist_specialties: exam?.specialist_specialties ?? [],
    refraction_option: exam?.refraction_option ?? 'anexar',
    retinography_option: exam?.retinography_option ?? 'anexar',
    import_option: exam?.import_option ?? '1',
    refraction_file: '',
    retinography_file: ''
  }
}

export default {
  name: "ExamFormModal",
  mixins: [formModalMixin('exams')],
  components: {
    SoftFileUpload,
    SoftSelectSearch,
    Form
  },
  mounted() {
    this.exam_modal = new bootstrap.Modal("#exam_form_modal" , {
      backdrop: 'static'
    });
    this.fetchFiles();
  },
  data() {
    return {
      loading: false,
      state: getState(),
      VisualAcuityOptions,
      RefractiveDisordersOptions,
      specialistOptions: [
        "Retina",
        "Glaucoma",
        "Córnea",
        "Oftalmopediatria",
        "Estrabismo",
        "Neuroftalmologia",
        "Catarata",
        "Órbita e Plástica Ocular",
        "Lentes de Contato",
        "Trauma Ocular",
        "Uveítes e Inflamações Oculares",
        "Oncologia Ocular",
        "Refração e Cirurgia Refrativa"
      ],
      refractionFiles: [],
      retinographyFiles: []
    }
  },
  computed: {
    ...mapState('exams', {
      open: state => state.form.open,
      exam: state => state.form.exam,
      patientId: state => state.form.patientId,
      title: state => state.form.exam?.id ? 'Editar Exame' : 'Novo Exame'
    }),

    schema() {
      return yup.object().shape({
        right_eye: yup.string().required().label("Olho direito"),
        left_eye: yup.string().required().label("Olho esquerdo"),
        refractive_disorder: yup.string().required().label("Distúrbios Refrativos"),
        portable_auto_refractor_img: yup.mixed().label('Auto Refrator Portátil')
          .when((_, schema) => this.exam ? schema.notRequired() : schema.notRequired()),
        retinography_img: yup.mixed().label('Retinografia')
          .when((_, schema) => this.exam ? schema.notRequired() : schema.notRequired()),
      });
    }
  },
  watch: {
    open (newOpen) {
      this.resetForm();
      if (newOpen) this.openExamModal();
      else this.closeExamModal();
    },
    'state.import_option': function(newFileName) {
      if (this.state.refraction_option === 'importar') {
        this.fetchRefractionFile(newFileName);
      }
    }
  },
  methods: {
    ...mapActions('exams', {
      onClose: "stopEditing"
    }),
    async handleSubmit() {
      this.loading = true
      try {
        const formData = {
          patientId: this.patientId,
          exam: {
            ...this.state,
            retroactive_disorder: this.state.refractive_disorder,
          }
        }

        if (this.exam) await this.$store.dispatch('exams/updateExam', formData);
        else await this.$store.dispatch('exams/addExam', formData);
        this.loading = false
      } catch (error) {
        this.loading = false;
      }
    },
    stopEditing() {
      this.$store.dispatch('exams/stopEditing');
    },
    openExamModal() {
      this.exam_modal.show()
    },
    closeExamModal() {
      this.exam_modal.hide()
    },
    resetForm() {
      this.state = getState(this.exam)
      this.$refs.examForm.resetForm();
    },
    async fetchFiles() {
      try {
        const retinographyResponse = await equipamentService.getRetinographyFiles();
        this.retinographyFiles = retinographyResponse;
        
        const refractionResponse = await equipamentService.getRefractionFiles();
        this.refractionFiles = refractionResponse;
      } catch (error) {
        console.error("Erro ao buscar arquivos:", error);
      }
    },
    async fetchRefractionFile(fileName) {
      try {
        const response = await equipamentService.getFile(fileName);
        this.state.portable_auto_refractor_img = response;
      } catch (error) {
        console.error("Erro ao buscar o arquivo de refração:", error);
      }
    },
  },
}
</script>
