<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="pb-0 card-header">
            <div class="d-flex flex-column gap-3">
              <div class="d-flex justify-content-start">
                <h5 class="mb-0">Pacientes</h5>
              </div>
              <div class="d-flex flex-column flex-lg-row gap-2">
                <div class="search-input-container flex-grow-1">
                  <input
                    type="text"
                    class="form-control"
                    v-model="searchQuery"
                    @input="searchPatients"
                    placeholder="Buscar..."
                  />
                </div>
                <div class="d-flex buttons-container">
                  <a class="mb-0 btn bg-gradient-success btn-sm me-2" @click="handleFileInput">
                    Importar Excel
                  </a>
                  <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" />
                  <a class="mb-0 btn bg-gradient-success btn-sm me-2" @click="exportExcel">
                    Gerar Excel
                  </a>
                  <a @click="startAdding" class="mb-0 btn bg-gradient-success btn-sm">
                    +&nbsp; Novo Paciente
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="container-fluid mt-2">
            <div class="row g-2 align-items-end justify-content-end">
              <div class="col-12 col-md-2">
                <label for="patientNames" class="form-label w-100">Filtrar por Nome:</label>
                <select v-model="selectedPatientNames" id="patientNames" class="form-control">
                  <option v-for="name in filteredPatientNames" :key="name" :value="name">{{ name }}</option>
                </select>
              </div>
              <div class="col-12 col-md-2">
                <label for="companies" class="form-label w-100">Filtrar por Empresa:</label>
                <select v-model="selectedCompanies" id="companies" class="form-control">
                  <option v-for="company in filteredCompanies" :key="company" :value="company">{{ company }}</option>
                </select>
              </div>
              <div class="col-12 col-md-auto mb-0">
                <button @click="clearFilters" class="btn bg-gradient-success w-100 mb-0">Limpar Filtros</button>
              </div>
            </div>
          </div>
          <div class="spinner-border" role="status" v-if="loading">
            <span class="sr-only">Carregando...</span>
          </div>
          <div class="px-0 pb-0 card-body" v-else>
            <div class="table-responsive">
              <table id="patients-list" ref="patientsList" class="table table-flush">
                <thead class="thead-light">
                  <tr>
                    <th @click="sortByName" class="click">
                      Nome
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': nameSortOrder === 1,
                          'fa fa-chevron-down': nameSortOrder === 2,
                          'fa fa-bars': nameSortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>CPF</th>
                    <th @click="sortByCompany" class="click">
                      Empresa
                      <span>
                        <i :class="{
                          'fa fa-chevron-up': companySortOrder === 1,
                          'fa fa-chevron-down': companySortOrder === 2,
                          'fa fa-bars': companySortOrder === 0
                        }"></i>
                      </span>
                    </th>
                    <th>Ativo</th>
                    <th>Criado em</th>
                    <th class="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody class="text-sm">
                  <patient-tile
                    v-for="(patient, index) in sortedPatients"
                    :patient="patient"
                    :key="index"
                  />
                </tbody>
              </table>
            </div>
          </div>
          <pagination
          :current ="page"
          :perPage="perPage"
          :onChangePage="changeToPage"
          :totalPages="lastPage"
          :totalValues="total"
          :onChangePerPage="changePerPage"
          />
        </div>
      </div>
    </div>
  </div>

  <input
    type="file"
    ref="fileInput"
    style="display: none"
    @change="handleFileUpload"
  />

  <patient-modal />
  <triage-modal />
  <exam-modal />
  <medical-record-modal />
</template>

<script>
import PatientTile from "./components/PatientTile"
import PatientModal from "./components/PatientModal"
import { mapState } from 'vuex'
import TriageModal from "./components/TriageModal.vue";
import ExamModal from "./components/ExamModal.vue";
import MedicalRecordModal from "./components/MedicalRecordModal.vue";
import Pagination from "../components/Pagination.vue";
import list_mixin from "../mixins/list_mixin";

export default {
  name: "Patients",
  components: {
    PatientTile,
    PatientModal,
    TriageModal,
    ExamModal,
    MedicalRecordModal,
    Pagination
  },

  mixins: [list_mixin('patients')],

  data() {
    return {
      searchQuery: "",
      nameSortOrder: 0,
      companySortOrder: 0,
      loading: false,
      selectedPatientNames: [],
      selectedCompanies: []
    };
  },

  computed: {
    ...mapState('patients', {
      patients: state => state.patients,
      loading: state => state.loading
    }),

    filteredPatientNames() {
      if (!Array.isArray(this.patients)) return [];
      return [...new Set(this.patients.map(patient => patient.name).filter(name => name != null))];
    },

    filteredCompanies() {
      if (!Array.isArray(this.patients)) return [];
      return [...new Set(this.patients.map(patient => patient.companyName).filter(company => company != null))];
    },

    filteredPatients() {
      if (!Array.isArray(this.patients)) return [];
      return this.patients.filter(patient => {
        const nameMatch = this.selectedPatientNames.length === 0 || this.selectedPatientNames.includes(patient.name);
        const companyMatch = this.selectedCompanies.length === 0 || this.selectedCompanies.includes(patient.companyName);
        const searchMatch = patient.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                          patient.companyName.toLowerCase().includes(this.searchQuery.toLowerCase());

        return nameMatch && companyMatch && searchMatch;
      });
    },

    sortedPatients() {
      const clonedPatients = [...this.filteredPatients];
      return clonedPatients.sort((a, b) => {
        const nameA = a.name ? a.name.toString() : "";
        const nameB = b.name ? b.name.toString() : "";
        const companyA = a.companyName ? a.companyName.toString() : "";
        const companyB = b.companyName ? b.companyName.toString() : "";

        if (this.nameSortOrder === 1) {
          return nameA.localeCompare(nameB);
        } else if (this.nameSortOrder === 2) {
          return nameB.localeCompare(nameA);
        } else if (this.companySortOrder === 1) {
          return companyA.localeCompare(companyB);
        } else if (this.companySortOrder === 2) {
          return companyB.localeCompare(companyA);
        } else {
          return 0;
        }
      });
    }
  },

  async created() {
    try {
      await this.$store.dispatch("patients/getPatients");
    } catch (error) {
      console.error(error);
    }
  },

  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.importExcel(file);
      }
    },

    handleFileInput() {
      this.$refs.fileInput.click();
    },

    async importExcel(file) {
      if (!file) {
        this.error = "Por favor, selecione um arquivo para importação.";
        return;
      }

      this.loading = true;
      const formData = new FormData();
      formData.append('file', file, file.name);
      try {
        await this.$store.dispatch('patients/importPatients', formData);
        this.loading = false;
        this.$refs.fileInput.value = "";
      } catch (error) {
        this.loading = false;
        this.error = "Erro ao importar o arquivo. Tente novamente.";
        console.error("Erro ao importar o arquivo:", error);
      }
    },

    exportExcel() {
      this.$store.dispatch('patients/exportAllPatients');
    },

    startAdding() {
      this.$store.dispatch('patients/startAdding');
    },

    async searchPatients() {
      await this.$store.dispatch('patients/findPatients', this.searchQuery);
    },
    sortByName() {
      this.nameSortOrder = (this.nameSortOrder + 1) % 3;
      this.companySortOrder = 0;
    },
    sortByCompany() {
      this.companySortOrder = (this.companySortOrder + 1) % 3;
      this.nameSortOrder = 0;
    },
    clearFilters() {
      this.selectedPatientNames = [];
      this.selectedCompanies = [];
    },
  },
};
</script>

<style scoped>
input[type="search"]::placeholder {
  font-family: FontAwesome, sans-serif;
  font-size: 14px;
}

.search-container {
  position: relative;
}

input[type="search"], input[type="text"] {
  padding-left: 15px;
  padding-top: 10px;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

@media (max-width: 768px) {
  .d-flex.flex-column.flex-md-row {
    gap: 1rem !important;
  }
}

input[type="search"]::placeholder {
  font-size: 20px;
  color: #6c757d; 
}

.search-input-container {
  width: 100%;
  min-width: 200px;
}

.buttons-container {
  white-space: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
  justify-content: flex-end;
  flex-shrink: 0; 
}

.buttons-container::-webkit-scrollbar {
  height: 4px;
}

.buttons-container::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

@media (max-width: 991px) {
  .search-input-container {
    max-width: 100%;
  }
  
  .buttons-container {
    margin-top: 0.5rem;
  }
}
</style>
