import axios from 'axios';

const BASE_URL = 'https://servicodados.ibge.gov.br/api/v1/localidades';

export default {
    async getStates() {
        const response = await axios.get(`${BASE_URL}/estados?orderBy=nome`);
        return response.data.map(state => ({
            value: state.sigla,
            label: state.nome
        }));
    },

    async getCitiesByState(uf) {
        if (!uf) return [];
        const response = await axios.get(`${BASE_URL}/estados/${uf}/municipios?orderBy=nome`);
        return response.data.map(city => ({
            value: city.nome,
            label: city.nome
        }));
    }
};
