import authService from "../services/auth.service";

const initialState = {
  ...authService.parseToken(),
  user: JSON.parse(localStorage.getItem('userData'))
};

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, userData) {
      const response = await authService.login(userData);
      commit("logMeIn", response);
      commit("setUser", response.user);
    },

    async logout({ commit, dispatch, state }) {
      try {
        if (state.loggedIn) await authService.logout();
      } catch (err) {
        console.error(err);
      } finally {
        commit("logMeOut");
        dispatch("resetState", null, { root: true })
      }
    },

    async register({ commit }, userData) {
      await authService.register(userData);
      commit("logMeIn");
    },

    async fetchCurrentUser({ commit }) {
      const userData = await authService.getCurrentUser();
      commit("setUser", userData);
      return userData;
    },
  },
  mutations: {
    logMeIn(state, response) {
      state.loggedIn = true;
      state.isAdmin = response.isAdmin;
      state.isTech = response.isTech;
      state.readOnly = response.readOnly;
    },
    setUser(state, user) {
      state.user = user;
    },
    logMeOut(state) {
      state.loggedIn = false;
      state.isAdmin = false;
      state.isTech = false;
      state.user = null;
    },
  },

  getters: {
    loggedIn(state) {
      return state.loggedIn;
    },
    isAdmin(state) {
      return state.isAdmin;
    },
    isTech(state){
      return state.isTech;
    }
  },
};