<!-- O gráfico de área polar compara dados em categorias com valores relativos. -->
<template>
    <TypedChart
      :type="'polarArea'"
      :data="data"
      :options="options"
    />
  </template>
  
  <script>

import { PolarArea } from 'vue-chartjs';
  
  export default {
    name: 'PolarAreaChart',
    components: { TypedChart: PolarArea },
    props: {
      data: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  </script>