<template>
    <tr>
        <td @click="startEditing">{{ id }}</td>
        <td @click="startEditing">{{ updated_at }}</td>
        <td> 
            <button class="btn btn-primary" @click="sendByEmail" :disabled="loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <span v-else>Enviar p/ e-mail</span>
            </button>
        </td>
    </tr>
</template>

<script>
import moment from 'moment';
import showSwal from '../../mixins/showSwal';

export default {
    props: ['exam'],
    data() {
        return {
            id: this.exam.id,
            created_at: moment(this.exam.created_at).format("DD/MM/YYYY HH:mm"),
            updated_at: moment(this.exam.updated_at).format("DD/MM/YYYY HH:mm"),
            loading: false,
        };
    },
    methods: {
        startEditing() {
            const data = {
                patientId: this.exam.patient_id,
                exam: this.exam
            }
            this.$store.dispatch('exams/startEditing', data)
        },
        async sendByEmail() {
            this.loading = true;
            try {
                const response = await this.$store.dispatch('exams/sendExamResult', this.exam.id);
                if (response && response.message === 'E-mail enviado com sucesso') {
                    showSwal.methods.showSwal({
                        type: 'success',
                        message: response.message,
                        timer: 5000,
                    });
                } else {
                    throw new Error('Resposta inesperada da API');
                }
            } catch (error) {
                console.error("Erro ao enviar exame por e-mail:", error);
                showSwal.methods.showSwal({
                    type: 'error',
                    message: 'Erro ao enviar exame por e-mail. Tente novamente mais tarde.',
                    timer: 5000,
                });
            } finally {
                this.loading = false;
            }
        }
    }
}
</script>
