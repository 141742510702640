<template>
  <div class="py-4 container-fluid">
    <h1 class="text-center text-primary mb-4">INDICADORES GERAIS</h1>

    <!-- Distribuição por Gênero -->
    <div class="row">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Distribuição por Gênero</h3>
            <table class="table table-bordered text-center">
              <thead class="thead-light">
                <tr>
                  <th>Gênero</th>
                  <th>Quantidade</th>
                  <th>Percentual</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Masculino</td>
                  <td>{{ generoMasculino.numero.toLocaleString('pt-BR') }}</td>
                  <td>{{ generoMasculino.percentual }}%</td>
                </tr>
                <tr>
                  <td>Feminino</td>
                  <td>{{ generoFeminino.numero.toLocaleString('pt-BR') }}</td>
                  <td>{{ generoFeminino.percentual }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Faixa Etária e Condições Sistêmicas -->
    <div class="row mt-4">
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Faixa Etária</h3>
            <BarChart :data="faixaEtariaData" :options="chartOptions" />
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card shadow-sm h-100">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Prevalência de Condições Sistêmicas</h3>
            <BarChart :data="condicoesSistemicasData" :options="condicoesChartOptions" />
          </div>
        </div>
      </div>
    </div>

    <!-- Indicadores Adicionais -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Indicadores Adicionais</h3>
            <div class="row">
              <div class="col-md-3">
                <div class="indicator-box">
                  <h5 class="text-muted">Gestantes</h5>
                  <h4 class="text-primary">{{ gestantes.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ gestantes.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="indicator-box">
                  <h5 class="text-muted">Lactantes</h5>
                  <h4 class="text-primary">{{ lactantes.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ lactantes.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="indicator-box">
                  <h5 class="text-muted">Uso de EPI</h5>
                  <h4 class="text-primary">{{ epi.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ epi.percentual }}%</p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="indicator-box">
                  <h5 class="text-muted">Tabagismo</h5>
                  <h4 class="text-primary">{{ tabagismo.numero.toLocaleString('pt-BR') }}</h4>
                  <p class="text-secondary">{{ tabagismo.percentual }}%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Uso de Tela -->
    <div class="row mt-4">
      <div class="col-12">
        <div class="card shadow-sm">
          <div class="card-body">
            <h3 class="text-center text-secondary mb-4">Uso de Tela por Dia</h3>
            <div style="max-width: 400px; margin: 0 auto;">
              <PieChart :data="usoTelaData" :options="usoTelaOptions" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "../../components/BarChart.vue";
import PieChart from "../../components/PieChart.vue";

export default {
  name: "IndicadoresGerais",
  components: {
    BarChart,
    PieChart
  },
  props: {
    triagens: {
      type: Number,
      required: true
    },
    generoMasculino: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    generoFeminino: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    faixaEtariaData: {
      type: Object,
      required: true
    },
    condicoesSistemicasData: {
      type: Object,
      required: true
    },
    gestantes: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    lactantes: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    epi: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    tabagismo: {
      type: Object,
      required: true,
      validator: prop => 'numero' in prop && 'percentual' in prop
    },
    usoTelaData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: { display: false }
        },
        scales: {
          y: {
            beginAtZero: true
          }
        }
      },
      condicoesChartOptions: {
        responsive: true,
        plugins: {
          legend: { display: true },
          title: {
            display: true,
            text: 'Distribuição por Gênero'
          }
        },
        scales: {
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Número de Casos'
            }
          },
          x: {
            title: {
              display: true,
              text: 'Condições'
            }
          }
        }
      },
      usoTelaOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              padding: 20
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.container-fluid {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
}

h1 {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
  margin-bottom: 15px;
}

.card {
  border-radius: 10px;
  background-color: white;
}

.card-body {
  text-align: center;
}

.horizontal-list {
  display: flex;
  justify-content: start;
}

.horizontal-list .list-group-item {
  margin-right: 20px;
  border: 1px solid #ddd;
  padding: 10px;
  background-color: #fff;
}

.indicator-box {
  padding: 15px;
  text-align: center;
  border-radius: 8px;
  background-color: #ffffff;
}

.indicator-box h4 {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 10px 0;
}

.indicator-box p {
  margin: 0;
  font-size: 0.9rem;
}
</style>
