import api from './axios'; 

export default {
  async getSupports(page = 1) {
    const response = await api.get(`/supports?page=${page}`);
    if (response.data.data) {
      response.data.data = response.data.data.map(support => {
        let parsedEquipaments = [];
        try {
          parsedEquipaments = JSON.parse(support.equipaments || '[]');
        } catch (e) {
          console.error('Error parsing equipaments:', e);
        }
        return {
          ...support,
          equipaments: parsedEquipaments
        };
      });
    }
    return response.data;
  },

  async getAllSupports() {
    const response = await api.get(`/supports?all=true`);
    return response.data;
  },

  async addSupports(supports) {
    const response = await api.post('/supports', supports);
    return response.data;
  },

  async updateSupports(supports) {
    const equipaments = supports.equipaments || [];
    
    const data = {
      ...supports,
      equipaments: JSON.stringify(equipaments)
    };
    
    const response = await api.put(`/supports/${supports.id}`, data);
    return {
      ...response.data,
      equipaments: Array.isArray(equipaments) ? equipaments : JSON.parse(response.data.equipaments || '[]'),
      user_id: response.data.user_id || supports.user_id,
      company_id: response.data.company_id || supports.company_id,
      data_atendimento: response.data.data_atendimento || supports.data_atendimento,
      observacoes: response.data.observacoes || supports.observacoes
    };
  },

  async deleteSupports(id) {
    const response = await api.delete(`/supports/${id}`);
    return response.status === 204; 
  }
}
