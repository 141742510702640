<template>
    <tr @click="startEditing">
      <td>{{ name }}</td>
      <td>{{ cpf }}</td>
      <td>{{ company }}</td>
      <td>{{ active }}</td>
      <td>{{ created_at }}</td>
      <td class="d-flex gap-2 justify-content-center">
        <button 
          class="btn btn-success"
          @click.stop="openTriageModal"
          data-toggle="tooltip"
          data-placement="top"
          title="Triagem"
        >
          <font-awesome-icon :icon="['fas', 'kit-medical']" />
        </button>
        <button 
          class="btn btn-success"
          @click.stop="openExamModal"
          data-toggle="tooltip"
          data-placement="top"
          title="Exames"
        >
          <font-awesome-icon :icon="['fas', 'stethoscope']" />
        </button>
        <button
            class="btn btn-success"
            @click.stop="openMedicalRecordModal"
            data-toggle="tooltip"
            data-placement="top"
            title="Prontuário"
        >
            <font-awesome-icon :icon="['fas', 'notes-medical']" />
        </button>
      </td>
    </tr>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faKitMedical, faStethoscope, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import moment from 'moment';

library.add(faKitMedical, faStethoscope, faNotesMedical);

export default {
    props: ['patient'],
    components: {
        FontAwesomeIcon
    },
    computed: {
        name() {
            return this.patient.name;
        },
        cpf() {
            return this.patient.cpf;
        },
        company() {
            return this.patient.companyName;
        },
        active() {
            return this.patient.active ? "Sim" : "Não";
        },
        created_at() {
            return moment(this.patient.created_at).format("DD/MM/YYYY HH:mm");
        }
    },
    methods: {
        startEditing() {
            this.$store.dispatch('patients/startEditing', this.patient)
        },
        openTriageModal() {
            this.$store.dispatch('triages/showModal', this.patient.id)
        },
        openExamModal() {
            this.$store.dispatch('exams/showModal', this.patient.id)
        },
        openMedicalRecordModal() {
            this.$store.dispatch('medicalRecords/showModal', this.patient.id)
        },
    }
}
</script>

<style scoped>
</style>
