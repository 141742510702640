<!-- O gráfico de bolhas representa dados tridimensionais com a posição e tamanho da bolha. -->
<template>
    <TypedChart
      :type="'bubble'"
      :data="data"
      :options="options"
    />
  </template>
  
  <script>

import { Bubble } from 'vue-chartjs';
  
  export default {
    name: 'BubbleChart',
    components: { TypedChart: Bubble },
    props: {
      data: {
        type: Object,
        required: true,
      },
      options: {
        type: Object,
        default: () => ({}),
      },
    },
  };
  </script>