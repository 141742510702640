import leadActivitiesService from "../services/leadActivities.service";
import { parseFormError } from "../services/utils";

const initialState = {
    leadActivities: [],
    loading: false,
    form: {
        open: false,
        leadActivity: null,
        activityId: null,
        error: null,
    },
};

export const leadActivities = {
    namespaced: true,
    state: initialState,
    actions: {

        async getLeadActivitiesByLeadId({ commit }, leadId) {
            commit("SET_LOADING", true);
            try {
                const response = await leadActivitiesService.getLeadActivitiesByLeadId(leadId);
                commit("SET_LEAD_ACTIVITIES", response);
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async getAllLeadActivities({ commit }) {
            commit("SET_LOADING", true);
            try {
                const response = await leadActivitiesService.getAllLeadActivities();
                commit("SET_LEAD_ACTIVITIES", response); 
            } finally {
                commit("SET_LOADING", false);
            }
        },

        async addLeadActivity({ dispatch, commit }, leadActivity) {
            try {
                commit("SET_FORM_ERROR", null);
                await leadActivitiesService.addLeadActivity(leadActivity);
                dispatch("getLeadActivitiesByLeadId");
                commit("RESET_FORM");
                dispatch("leads/stopEditing", null, { root: true });
            } catch (error) {
                commit("SET_FORM_ERROR", parseFormError(error));
            }
        },

        async updateLeadActivity({ dispatch, commit }, activityId ) {
            try {
                commit("SET_FORM_ERROR", null);
                await leadActivitiesService.updateLeadActivity(activityId);
                dispatch("getLeadActivitiesByLeadId", activityId.lead_id);
            } catch (error) {
                console.error("Erro ao editar a atividade:", error);
                commit("SET_FORM_ERROR", parseFormError(error));
            }
        },

        async deleteLeadActivity({ dispatch, commit }, { activityId, leadId } ) {
            try {
              await leadActivitiesService.deleteLeadActivity(activityId);
              dispatch("getLeadActivitiesByLeadId", leadId);
            } catch (error) {
              console.error("Erro ao deletar a atividade:", error);
              commit("SET_FORM_ERROR", parseFormError(error));
            }
          },
    },
    
    mutations: {
        SET_LEAD_ACTIVITIES(state, leadActivities) {
            state.leadActivities = leadActivities;
        },
        SET_LOADING(state, value) {
            state.loading = value;
        },
        SET_FORM_ERROR(state, error) {
            state.form.error = error;
        },
        RESET_FORM(state) {
            state.form = { open: false, leadActivity: null, error: null };
        },
    },
};
