export default function auth({ next, store, to }) {
  // Verifica se o usuário está logado
  if (!store.getters["auth/loggedIn"] && !to.query?.token && to.path !== '/retinography') {
    return next({
      name: "Login",
    });
  }

  if (to.meta.isAdmin && !store.getters["auth/isAdmin"] && !store.getters["auth/isTech"]) {
    return next({ name: "" }); 
  }

  // Permite o acesso
  next();
}
