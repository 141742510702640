<template>
  <div class="card mb-4">
    <div class="card-body">
      <div class="filter-container">
        <div class="filter-select">
          <label class="form-label">Filtrar por Empresa</label>
          <multiselect
            v-model="selectedCompany"
            :options="companies"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            placeholder="Selecione uma empresa"
            label="label"
            track-by="value"
          />
        </div>
        <div class="filter-buttons">
          <button 
            class="btn btn-primary me-2" 
            @click="applyFilter"
            :disabled="loading"
          >
            <span v-if="loading" class="spinner-border spinner-border-sm me-1"></span>
            Filtrar
          </button>
          <button 
            class="btn btn-secondary" 
            @click="clearFilter"
            :disabled="!selectedCompany"
          >
            Limpar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import "vue-multiselect/dist/vue-multiselect.css"
import 'vue-toast-notification/dist/theme-default.css'

export default {
  name: 'DashboardFilter',
  components: { Multiselect },
  data() {
    return {
      selectedCompany: null,
      loading: false
    }
  },
  computed: {
    companies() {
      return this.$store.state.companies.options
    }
  },
  methods: {
    async applyFilter() {
      this.loading = true
      try {
        await this.$store.dispatch('dashboard/filterByCompany', this.selectedCompany?.value)
        this.$root.$toast.success('Filtro aplicado com sucesso', {
          position: 'top-right',
          duration: 3000
        })
      } finally {
        this.loading = false
      }
    },
    async clearFilter() {
      this.selectedCompany = null
      this.loading = true
      try {
        await this.$store.dispatch('dashboard/resetFilter')
      } finally {
        this.loading = false
      }
    }
  },
  async created() {
    await this.$store.dispatch('companies/getOptions')
  }
}
</script>

<style scoped>
.filter-container {
  display: flex;
  align-items: flex-end;
  gap: 16px;
}

.filter-select {
  flex: 1;
}

.filter-buttons {
  display: flex;
  align-items: center;
  height: 38px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
