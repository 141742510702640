<!-- Utilizacação de cada gráfico para exemplo -->
<template>
    <div class="py-4 container-fluid">
      <p>Exemplos de gráficos p/ implementação no sistema!</p>
      <div class="charts-container">
        <div>
          <h2>Gráfico de Barras (Bar Chart)</h2>
          <p>O gráfico de barras é ideal para comparar diferentes categorias de dados.</p>
          <BarChart :data="barChartData" :options="barChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico Rosca (Doughnut Chart)</h2>
          <p>O gráfico de rosca mostra proporções de um total, semelhante ao gráfico de pizza.</p>
          <DoughnutChart :data="doughnutChartData" :options="doughnutChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico Linha (Line Chart)</h2>
          <p>O gráfico de linha é usado para visualizar tendências ao longo do tempo.</p>
          <LineChart :data="lineChartData" :options="lineChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico Pizza (Pie Chart)</h2>
          <p>O gráfico de pizza é útil para mostrar partes de um todo de forma visualmente simples.</p>
          <PieChart :data="pieChartData" :options="pieChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico de Área Polar (Polar Area Chart)</h2>
          <p>O gráfico de área polar compara dados em categorias com valores relativos.</p>
          <PolarAreaChart :data="polarAreaChartData" :options="polarAreaChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico Radar (Radar Chart)</h2>
          <p>O gráfico de radar é útil para mostrar dados multidimensionais em um formato radial.</p>
          <RadarChart :data="radarChartData" :options="radarChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico de Bolhas (Bubble Chart)</h2>
          <p>O gráfico de bolhas representa dados tridimensionais com a posição e tamanho da bolha.</p>
          <BubbleChart :data="bubbleChartData" :options="bubbleChartOptions" />
        </div>
  
        <div>
          <h2>Gráfico de Dispersão (Scatter Chart)</h2>
          <p>O gráfico de dispersão exibe pontos em um plano cartesiano para observar correlações entre variáveis.</p>
          <ScatterChart :data="scatterChartData" :options="scatterChartOptions" />
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  
  import BarChart from '../components/BarChart.vue';
  import DoughnutChart from '../components/DoughnutChart.vue';
  import LineChart from '../components/LineChart.vue';
  import PieChart from '../components/PieChart.vue';
  import PolarAreaChart from '../components/PolarAreaChart.vue';
  import RadarChart from '../components/RadarChart.vue';
  import BubbleChart from '../components/BubbleChart.vue';
  import ScatterChart from '../components/ScatterChart.vue';
  
  export default {
    name: 'Dashboard',
    components: {
      BarChart,
      DoughnutChart,
      LineChart,
      PieChart,
      PolarAreaChart,
      RadarChart,
      BubbleChart,
      ScatterChart,
    },
    data() {
      return {
        barChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        barChartOptions: {responsive: true },
  
        doughnutChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        doughnutChartOptions: {responsive: true },
  
        lineChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        lineChartOptions: {responsive: true },
  
        pieChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        pieChartOptions: {responsive: true },
  
        polarAreaChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        polarAreaChartOptions: {responsive: true },
  
        radarChartData: { datasets: [{ data: [10, 20, 30] }], labels: ['A', 'B', 'C'] },
        radarChartOptions: {responsive: true },
  
        bubbleChartData: { datasets: [{ data: [{ x: 10, y: 20, r: 5 }] }] },
        bubbleChartOptions: {responsive: true },
  
        scatterChartData: { datasets: [{ data: [{ x: 10, y: 20 }, { x: 15, y: 25 }] }] },
        scatterChartOptions: {responsive: true },
      };
    },
  };
  </script>
  
  <style>
  .charts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
  }
  
  .charts-container > div {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
  </style>
  