import api from "./axios";
import VueJwtDecode from "vue-jwt-decode";

export default {
    async getCompanies(page = 1, per_page = 10) {
        const response = await api.get(`/companies?page=${page}&per_page=${per_page}`);
        return response.data;
    },

    async exportAllCompanies() {
        const response = await api.get('companies/exportAllCompanies', { responseType: 'blob' });
        return response.data;
    },

    async getOptions(companytype = null){
        const response = companytype
            ? await api.get(`/company/options?companytype=${companytype}`)
            : await api.get(`/company/options`)
        return response.data;
    },
    async addCompany(company) {
        const response = await api.post("/companies", company)

        return response.data;
    },
    async updateCompany(company) {
        const response = await api.put(`/companies/${company.id}`, company)

        return response.data;
    },
    async tokenGenerate(company_id) {
        const response = await api.post("/companies/token/generate", {company_id: company_id})
        
        return response.data;
    },
    async tokenValidate() {
        const token = localStorage.getItem("urlToken");
        const decodedToken = VueJwtDecode.decode(JSON.parse(token));
        const response = await api.post("/companies/token/validate", {token_id: decodedToken?.jti})
        localStorage.setItem("company_id", response.data?.company_id);
        
        return response.data;
    },

    async findCompanies(query, page= 1, per_page = 10) {
        const response = await api.get(`/companies`, {
            params: {
                query: query,
                page: page,
                per_page: per_page
            }
        })
        return response.data;
    }
}