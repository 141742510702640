<template>
  <div>
    <!-- Card existente com métricas -->
    <div class="card mt-4">
      <div class="card-header">
        <div class="row">
          <div class="col-md-4">
            <label for="dateFilter" class="form-label">Filtrar por período</label>
            <select id="dateFilter" class="form-select" v-model="selectedOption" @change="updateDateRange">
              <option v-for="option in dateOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </div>
          <div class="col-md-4">
            <label for="startDate" class="form-label">Data Inicial</label>
            <input type="date" id="startDate" class="form-control" v-model="startDate">
          </div>
          <div class="col-md-4">
            <label for="endDate" class="form-label">Data Final</label>
            <input type="date" id="endDate" class="form-control" v-model="endDate">
          </div>    
        </div>

      </div>
      <div class="card-body">
        <div class="row">
          <!-- Total em Vendas -->
          <div class="col-md-4">
            <div class="sales-total">
              <h6 class="text-uppercase text-muted mb-2">Total em Vendas</h6>
              <h2 class="text-success mb-3">R$ {{ formatValue(totalSales) }}</h2>
              <div class="progress-wrapper">
                <div class="progress-info">
                  <div class="progress-percentage">
                    <span>{{ progressPercentage }}% da meta</span>
                  </div>
                </div>
                <div class="progress">
                  <div 
                    class="progress-bar bg-gradient-success" 
                    :style="{ width: `${progressPercentage}%` }"
                    role="progressbar" 
                    :aria-valuenow="progressPercentage" 
                    aria-valuemin="0" 
                    aria-valuemax="100">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Ticket Médio -->
          <div class="col-md-4">
            <div class="average-ticket">
              <h6 class="text-uppercase text-muted mb-2">Ticket Médio</h6>
              <h2 class="text-primary mb-3">R$ {{ formatValue(averageTicket) }}</h2>
              <p class="text-sm mb-0">
                <span class="text-success mr-2">
                  <i class="fa fa-arrow-up"></i> {{ ticketGrowth }}%
                </span>
                <span class="text-muted">desde o último mês</span>
              </p>
            </div>
          </div>

          <!-- Conversão Média -->
          <div class="col-md-4">
            <div class="conversion-rate">
              <h6 class="text-uppercase text-muted mb-2">Conversão Média</h6>
              <h2 class="text-info mb-3">{{ conversionRate }}%</h2>
              <p class="text-sm mb-0">
                <span class="text-success mr-2">
                  <i class="fa fa-arrow-up"></i> {{ conversionGrowth }}%
                </span>
                <span class="text-muted">desde o último mês</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <!-- Gráficos de Motivos -->
    <div class="row mt-4">
      <!-- Top 5 Motivos de Ganho -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header pb-0">
            <h6 class="mb-0">Top 5 Motivos de Ganho</h6>
          </div>
          <div class="card-body">
            <canvas ref="winReasonChart"></canvas>
          </div>
        </div>
      </div>

      <!-- Top 5 Motivos de Perda -->
      <div class="col-md-6">
        <div class="card">
          <div class="card-header pb-0">
            <h6 class="mb-0">Top 5 Motivos de Perda</h6>
          </div>
          <div class="card-body">
            <canvas ref="lossReasonChart"></canvas>
          </div>
        </div>
      </div>
    </div>

    <!-- Nova seção de Previsão -->
    <!-- <div class="card mt-4">
      <div class="card-body">
        <div class="row justify-content-center"> -->
          <!-- Previsão de Vendas -->
          <!-- <div class="col-md-4 mx-2">
            <div class="sales-prediction">
              <h6 class="text-uppercase text-muted mb-2">Previsão de Vendas</h6>
              <h2 class="text-warning mb-3">R$ {{ formatValue(salesPrediction) }}</h2>
              <p class="text-sm mb-0">
                <span class="text-success mr-2">
                  <i class="fa fa-arrow-up"></i> {{ predictionGrowth }}%
                </span>
                <span class="text-muted">projeção para o próximo mês</span>
              </p>
            </div>
          </div> -->

          <!-- Ticket Médio Previsto -->
          <!-- <div class="col-md-4 mx-2">
            <div class="prediction-ticket">
              <h6 class="text-uppercase text-muted mb-2">Ticket Médio Previsto</h6>
              <h2 class="text-warning mb-3">R$ {{ formatValue(predictedTicket) }}</h2>
              <p class="text-sm mb-0">
                <span class="text-success mr-2">
                  <i class="fa fa-arrow-up"></i> {{ predictedTicketGrowth }}%
                </span>
                <span class="text-muted">projeção para o próximo mês</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- Nova seção de Rankings Detalhados -->
    <div class="row mt-4">
      <!-- Ranking Vendedores -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h6 class="mb-0">Ranking Vendedores</h6>
          </div>
          <div class="card-body">
            <div class="ranking-list">
              <div v-for="(seller, index) in detailedSellers" :key="index" class="ranking-item mb-3">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-sm">{{ seller.name }}</span>
                  <span class="text-sm font-weight-bold">{{ seller.count }}</span>
                </div>
                <div class="progress mt-2">
                  <div class="progress-bar bg-gradient-primary"
                       :style="{ width: (seller.count / maxSellerCount * 100) + '%' }"
                       role="progressbar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ranking Cidades -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h6 class="mb-0">Ranking Cidades</h6>
          </div>
          <div class="card-body">
            <div class="ranking-list">
              <div v-for="(city, index) in cities" :key="index" class="ranking-item mb-3">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-sm">{{ city.name }}</span>
                  <span class="text-sm font-weight-bold">{{ city.count }}</span>
                </div>
                <div class="progress mt-2">
                  <div class="progress-bar bg-gradient-warning"
                       :style="{ width: (city.count / maxCityCount * 100) + '%' }"
                       role="progressbar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ranking Canais de Vendas -->
      <div class="col-md-4">
        <div class="card">
          <div class="card-header pb-0">
            <h6 class="mb-0">Canais de Vendas</h6>
          </div>
          <div class="card-body">
            <div class="ranking-list">
              <div v-for="(channel, index) in detailedChannels" :key="index" class="ranking-item mb-3">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-sm">{{ channel.name }}</span>
                  <span class="text-sm font-weight-bold">{{ channel.count }}</span>
                </div>
                <div class="progress mt-2">
                  <div class="progress-bar bg-gradient-info"
                       :style="{ width: (channel.count / maxChannelCount * 100) + '%' }"
                       role="progressbar">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Funil de Vendas -->
    <div class="card mt-4">
      <div class="card-header pb-0">
        <h6 class="mb-0">Funil de Vendas</h6>
      </div>
      <div class="card-body">
        <div class="funnel-container">
          <div class="funnel-step">
            <div class="funnel-number">{{ funnelData.oportunidades }}</div>
            <div class="funnel-label">Oportunidades</div>
            <div class="funnel-arrow">
              <i class="fas fa-arrow-down text-success"></i>
              <span class="conversion-rate">{{ funnelData.oportunidades_rate }}%</span>
            </div>
          </div>

          <div class="funnel-step">
            <div class="funnel-number">{{ funnelData.contato1 }}</div>
            <div class="funnel-label">Contato 1</div>
            <div class="funnel-arrow">
              <i class="fas fa-arrow-down text-success"></i>
              <span class="conversion-rate">{{ funnelData.contato1_rate }}%</span>
            </div>
          </div>

          <div class="funnel-step">
            <div class="funnel-number">{{ funnelData.contato2 }}</div>
            <div class="funnel-label">Contato 2</div>
            <div class="funnel-arrow">
              <i class="fas fa-arrow-down text-success"></i>
              <span class="conversion-rate">{{ funnelData.contato2_rate }}%</span>
            </div>
          </div>

          <div class="funnel-step">
            <div class="funnel-number">{{ funnelData.reuniao }}</div>
            <div class="funnel-label">Reunião</div>
            <div class="funnel-arrow">
              <i class="fas fa-arrow-down text-success"></i>
              <span class="conversion-rate">{{ funnelData.reuniao_rate }}%</span>
            </div>
          </div>

          <div class="funnel-step">
            <div class="funnel-number">{{ funnelData.total }}</div>
            <div class="funnel-label">Total Convertido</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import { ref, onMounted, watch, getCurrentInstance } from "vue";
import { DateTime } from "luxon";

export default {
  name: 'LeadsDashboard',
  setup() {
	const { proxy } = getCurrentInstance();
    const selectedOption = ref("mês");
    const startDate = ref("");
    const endDate = ref("");

    const dateOptions = [
      { label: "Mês", value: "mês" },
      { label: "Trimestre", value: "trimestre" },
      { label: "Semestre", value: "semestre" },
      { label: "Personalizado", value: "custom" },
    ];

    const updateDateRange = () => {
      const now = DateTime.local();
      let start, end;

      switch (selectedOption.value) {
        case "mês":
          start = now.startOf("month").toISODate();
          end = now.toISODate();
          break;
        case "trimestre":
          start = now.startOf("quarter").toISODate();
          end = now.toISODate();
          break;
        case "semestre":
          start = now.month <= 6 
            ? now.startOf("year").toISODate() 
            : now.set({ month: 7, day: 1 }).toISODate();
          end = now.toISODate();
          break;
      }

      startDate.value = start;
      endDate.value = end;
    };

    const loadDashboard = async () => {
      await proxy.$store.dispatch('leads/getDashboard', { startDate: startDate.value, endDate: endDate.value })
	  	.then((response) => {
			proxy.totalSales = response.totalSales ?? 0,
			proxy.salesGoal = response?.salesGoal ?? 0,
			proxy.averageTicket = response?.averageTicket ?? 0,
			proxy.ticketGrowth = response?.ticketGrowth ?? 0,
			proxy.conversionRate = response?.conversionRate ?? 0,
			proxy.conversionGrowth = response?.conversionGrowth ?? 0,
			// proxy.salesPrediction = response?.salesPrediction ?? 0,
			// proxy.predictionGrowth = response?.predictionGrowth ?? 0,
			// proxy.predictedTicket = response?.predictedTicket ?? 0,
			// proxy.predictedTicketGrowth = response?.predictedTicketGrowth ?? 0,
			// proxy.sellers = response?.sellers ?? [],
			// proxy.channels = response?.channels ?? [],
			// proxy.products = response?.products ?? [],
			proxy.winReasons = response?.winReasons ?? [],
			proxy.lossReasons = response?.lossReasons ?? [],
			proxy.detailedSellers = response?.detailedSellers ?? [],
			proxy.detailedProducts = response?.detailedProducts ?? [],
			proxy.cities = response?.cities ?? [],
			proxy.detailedChannels = response?.detailedChannels ?? [],
			proxy.funnelData = response?.funnelData ?? []
		}).then(proxy.initCharts);
    };

    onMounted(updateDateRange);

    // Adicionando watch para startDate e endDate
    watch([startDate, endDate], loadDashboard);

    return { selectedOption, startDate, endDate, dateOptions, updateDateRange, loadDashboard };
  },
  data() {
    return {
      winReasonChartInstance: null,
      lossReasonChartInstance: null,
      totalSales: 0,
      salesGoal: 0,
      averageTicket: 0,
      ticketGrowth: 0,
      conversionRate: 0,
      conversionGrowth: 0,
      // salesPrediction: 0,
      // predictionGrowth: 0,
      // predictedTicket: 0,
      // predictedTicketGrowth: 0,
      // sellers: [],
      // channels: [],
      // products: [],
      winReasons: [],
      lossReasons: [],
      detailedSellers: [],
      detailedProducts: [],
      cities: [],
      detailedChannels: [],
      funnelData: {
        oportunidades: 0,
        oportunidades_rate: 0,
        contato1: 0,
        contato1_rate: 0,
        contato2: 0,
        contato2_rate: 0,
        reuniao: 0,
        reuniao_rate: 0,
        total: 0
      }
    }
  },
  computed: {
    progressPercentage() {
      return Math.round((this.totalSales / this.salesGoal) * 100)
    },
    maxSellerCount() {
      return Math.max(...this.detailedSellers.map(s => s.count));
    },
    maxProductCount() {
      return Math.max(...this.detailedProducts.map(p => p.count));
    },
    maxCityCount() {
      return Math.max(...this.cities.map(c => c.count));
    },
    maxChannelCount() {
      return Math.max(...this.detailedChannels.map(c => c.count));
    }
  },
  methods: {
    formatValue(value) {
      return value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    },
    initCharts() { 
		if (this.lossReasonChartInstance || this.winReasonChartInstance) {
			this.winReasonChartInstance.destroy();
			this.lossReasonChartInstance.destroy();
		}
		this.winReasonChartInstance = new Chart(this.$refs.winReasonChart, {
		type: 'bar',
		data: {
			labels: this.winReasons.map(item => item.reason),
			datasets: [{
			label: 'Quantidade',
			data: this.winReasons.map(item => item.count),
			backgroundColor: 'rgba(45, 206, 137, 0.6)',
			borderColor: 'rgba(45, 206, 137, 1)',
			borderWidth: 1
			}]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
			y: {
				beginAtZero: true,
				ticks: {
				stepSize: 1,
				callback: function(value) {
					return value.toFixed(0);
				}
				}
			}
			},
			plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
				label: function(context) {
					return `Quantidade: ${context.parsed.y}`;
				}
				}
			}
			}
		}
		});
		
		this.lossReasonChartInstance = new Chart(this.$refs.lossReasonChart, {
		type: 'bar',
		data: {
			labels: this.lossReasons.map(item => item.reason),
			datasets: [{
			label: 'Quantidade',
			data: this.lossReasons.map(item => item.count),
			backgroundColor: 'rgba(255, 99, 132, 0.6)',
			borderColor: 'rgba(255, 99, 132, 1)',
			borderWidth: 1
			}]
		},
		options: {
			responsive: true,
			maintainAspectRatio: false,
			scales: {
			y: {
				beginAtZero: true,
				ticks: {
				stepSize: 1,
				callback: function(value) {
					return value.toFixed(0);
				}
				}
			}
			},
			plugins: {
			legend: {
				display: false
			},
			tooltip: {
				callbacks: {
				label: function(context) {
					return `Quantidade: ${context.parsed.y}`;
				}
				}
			}
			}
		}
		});
	}
  },
  mounted() {
    // this.loadDashboard();
	this.initCharts();
  }
}
</script>

<style scoped>
.sales-total, .average-ticket, .conversion-rate {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
}

.progress {
  height: 8px;
  margin-bottom: 1rem;
}

.progress-bar {
  transition: width 0.6s ease;
}

.text-success {
  color: #2dce89 !important;
}

h2 {
  font-size: 2.4rem;
  font-weight: 600;
}

.ranking-item {
  margin-bottom: 1.5rem;
}

.progress {
  height: 8px;
  margin-top: 0.5rem;
  border-radius: 4px;
  background-color: #f6f9fc;
}

.progress-bar {
  transition: width 0.6s ease;
}

.bg-gradient-primary {
  background-image: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
}

.bg-gradient-info {
  background-image: linear-gradient(310deg, #2152ff 0%, #21d4fd 100%);
}

.bg-gradient-success {
  background-image: linear-gradient(310deg, #2dce89 0%, #2dcecc 100%);
}

.card {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
}

.ranking-list {
  padding: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.font-weight-bold {
  font-weight: 600;
}

.card-body canvas {
  min-height: 300px;
}

.sales-prediction {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
}

.text-warning {
  color: #fb6340 !important;
}

.prediction-ticket {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
}

.bg-gradient-warning {
  background-image: linear-gradient(310deg, #fb6340 0%, #fbb140 100%);
}

.sales-prediction, .prediction-ticket {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: white;
  margin: 0 auto;
  max-width: 400px;
}

.funnel-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem;
  text-align: center;
}

.funnel-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 150px;
}

.funnel-number {
  font-size: 2.5rem;
  font-weight: 700;
  color: #344767;
  margin-bottom: 0.5rem;
}

.funnel-label {
  font-size: 1rem;
  color: #7b809a;
  font-weight: 500;
  text-transform: uppercase;
}

.funnel-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  color: #2dce89;
}

.funnel-arrow i {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
}

.conversion-rate {
  font-size: 0.875rem;
  font-weight: 600;
}

.funnel-step:last-child .funnel-arrow {
  display: none;
}
</style>
