<template>
    <tr @click="startEditing">
        <td>{{ lead.company_name }}</td>
        <td>{{ lead.name }}</td>
        <td>{{ lead.source }}</td>
        <td>{{ lead.funnel_stage }}</td>
        <td>{{ lead.temperature_stage }}</td>
        <td>{{ lead.city }}</td>
        <td>{{ formatDate(lead.created_at) }}</td>
        <td>{{ lead.assigned_user?.name || '-' }}</td>
        <td>
            <button class="" @click.stop="openActionsModal">
                <i class="fas fa-bars"></i>
            </button>
        </td>
    </tr>

    <!-- Modal -->
    <div class="modal fade" id="actionsModal" tabindex="-1" aria-labelledby="actionsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="actionsModalLabel">Encerramento do lead</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="mb-3">
                      <label class="form-label">
                        Selecione a ação
                      </label>
                      <div class="d-flex">
                        <div class="form-check me-3">
                          <input class="form-check-input" type="radio" id="ganho" value="ganho" v-model="selectedAction">
                          <label class="form-check-label" for="ganho">Ganho</label>
                        </div>
                        <div class="form-check">
                          <input class="form-check-input" type="radio" id="perda" value="perda" v-model="selectedAction">
                          <label class="form-check-label" for="perda">Perda</label>
                        </div>
                      </div>
                  </div>
                  <div v-if="selectedAction === 'ganho'">
                    <!-- Conteúdo específico para Ganho -->
                    <div class="mb-3">
                      <label for="reason" class="form-label">Motivo</label>
                      <select class="form-select" id="reason" v-model="reason">
                        <option value="Preço Competitivo">Preço Competitivo</option>
                        <option value="Atendimento">Atendimento</option>
                        <option value="Prazo de Entrega">Prazo de Entrega</option>
                        <option value="Condições de Pagamento">Condições de Pagamento</option>
                      </select>
                    </div>
                    <div class="mb-3">
                      <label for="price_unity" class="form-label">Valor Unitário</label>
                      <input type="number" class="form-control" id="price_unity" v-model="price_unity" @blur="calculateTotal">
                    </div>
                    <div class="mb-3">
                      <label for="quantity" class="form-label">Quantidade</label>
                      <input type="number" class="form-control" id="quantity" v-model="quantity" @blur="calculateTotal">
                    </div>
                    <div class="mb-3">
                      <label for="price_total" class="form-label">Valor Total</label>
                      <input type="number" class="form-control" id="price_total" v-model="price_total" @blur="calculateUnitOrQuantity">
                    </div>
                  </div>
                  <div v-if="selectedAction === 'perda'">
                    <!-- Conteúdo específico para Perda -->
                    <div class="mb-3">
                      <label for="reason" class="form-label">Motivo</label>
                      <select class="form-select" id="reason" v-model="reason">
                        <option value="Preço Alto">Preço Alto</option>
                        <option value="Concorrência">Concorrência</option>
                        <option value="Prazo Inadequado">Prazo Inadequado</option>
                        <option value="Falta de Recursos">Falta de Recursos</option>
                        <option value="Mudança de Prioridade">Mudança de Prioridade</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                    <button type="button" class="btn btn-primary" @click="saveAction">Salvar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min.js';
import showSwal from "/src/mixins/showSwal.js";

export default {
    props: ['lead'],
    data() {
        return {
            name: this.lead.name,
            company_name: this.lead.company_name,
            email: this.lead.email,
            phone: this.lead.phone || 'Não informado',
            source: this.lead.source || 'Não informado',
            status: this.getLeadStatus(this.lead.status),
            funnel_stage: this.lead.funnel_stage || 'Não informado',
            temperature_stage: this.lead.temperature_stage || 'Não informado',
            state: this.lead.state || 'Não informado',  
            city: this.lead.city || 'Não informado',    
            zone: this.lead.zone || 'Não informado',   
            selectedAction: '',
            price_unity: '',
            quantity: '',
            price_total: '',
            reason: '',
            lastUpdatedField: '',
            created_at: moment(this.lead.created_at).format("DD/MM/YYYY HH:mm"),
        };
    },
    watch: {
        price_unity(newValue) {
            if (this.lastUpdatedField === 'price_total' || this.lastUpdatedField === 'quantity') {
                return;
            }
            this.lastUpdatedField = 'price_unity';
            if (this.quantity) {
                this.price_total = newValue * this.quantity;
            }
        },
        quantity(newValue) {
            if (this.lastUpdatedField === 'price_total' || this.lastUpdatedField === 'price_unity') {
                return;
            }
            this.lastUpdatedField = 'quantity';
            if (this.price_unity) {
                this.price_total = newValue * this.price_unity;
            }
        },
        price_total(newValue) {
            if (this.lastUpdatedField === 'price_unity' || this.lastUpdatedField === 'quantity') {
                return;
            }
            this.lastUpdatedField = 'price_total';
            if (this.quantity) {
                this.price_unity = newValue / this.quantity;
            } else if (this.price_unity) {
                this.quantity = newValue / this.price_unity;
            }
        }
    },
    methods: {
        startEditing() {
            this.$store.dispatch('leads/startEditing', this.lead);
        },
        getLeadStatus(status) {
            const statusMapping = {
                'new': 'Novo',
                'in_progress': 'Em Progresso',
                'closed': 'Concluído'
            };
            return statusMapping[status] || 'Desconhecido';
        },
        formatDate(date) {
            if (!date) return '';
            return new Date(date).toLocaleDateString('pt-BR');
        },
        openActionsModal() {
            this.selectedAction = '';
            this.reason = '';
            this.quantity = '';
            this.price_unity = '';
            this.price_total = '';
            this.selectedAction = '';

            const modal = new bootstrap.Modal(document.getElementById('actionsModal'));
            modal.show();
        },
        calculateTotal() {
            if (this.price_unity && this.quantity) {
                this.price_total = this.price_unity * this.quantity;
            }
        },
        calculateUnitOrQuantity() {
            if (this.price_total) {
                if (this.quantity) {
                    this.price_unity = this.price_total / this.quantity;
                } else if (this.price_unity) {
                    this.quantity = this.price_total / this.price_unity;
                }
            }
        },
        async saveAction() {
            var leadCopy = { ...this.lead }; // Cria uma cópia do objeto lead
            if (this.selectedAction === 'ganho') {
                if (!this.price_unity || !this.quantity || !this.price_total || !this.reason) {
                    showSwal.methods.showSwal({
                        type: 'error',
                        message: 'Todos os campos são obrigatórios para a ação de ganho.',
                        timer: 5000
                    });
                    return;
                }
                leadCopy.status = this.selectedAction;
                leadCopy.quantity = this.quantity;
                leadCopy.price_unity = this.price_unity;
                leadCopy.price_total = this.price_total;
                leadCopy.reason = this.reason;
            } else if (this.selectedAction === 'perda') {
                if (!this.reason) {
                    showSwal.methods.showSwal({
                        type: 'error',
                        message: 'O campo motivo é obrigatório para a ação de perda.',
                        timer: 5000
                    });
                    return;
                }
                leadCopy.status = this.selectedAction;
                leadCopy.reason = this.reason;
            } else {
                showSwal.methods.showSwal({
                    type: 'error',
                    message: 'Selecione uma ação (ganho ou perda).',
                    timer: 5000
                });
                return;
            }

            await this.$store.dispatch('leads/updateLead', leadCopy);

            showSwal.methods.showSwal({
                type: 'success',
                message: 'Lead encerrado!',
                timer: 5000
            });

            const modal = bootstrap.Modal.getInstance(document.getElementById('actionsModal'));
            modal.hide();
        }
    }
};
</script>
