import api from "./axios";

export default {
    async getPatientsCount(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/patients/count', { params });
        return response.data;
    },
    async getTriageStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/statistics', { params });
        return response.data;
    },
    async getExamStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/exams/statistics', { params });
        return response.data;
    },
    async getGenderStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/patients/gender-statistics', { params });
        return response.data;
    },
    async getAgeStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/patients/age-statistics', { params });
        return response.data;
    },
    async getConditionsStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/conditions-statistics', { params });
        return response.data;
    },
    async getHealthHabitsStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/health-habits-statistics', { params });
        return response.data;
    },
    async getScreenTimeStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/screen-time-statistics', { params });
        return response.data;
    },
    async getEyeComplaintsStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/eye-complaints-statistics', { params });
        return response.data;
    },
    async getEyeDiseasesStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/eye-diseases-statistics', { params });
        return response.data;
    },
    async getVisionAidsStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/vision-aids-statistics', { params });
        return response.data;
    },
    async getEyeSurgeriesStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/triages/eye-surgeries-statistics', { params });
        return response.data;
    },
    async getVisionQualityStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/exams/vision-quality-statistics', { params });
        return response.data;
    },
    async getRetinographyStatistics(companyId) {
        const params = companyId ? { company_id: companyId } : {};
        const response = await api.get('/exams/retinography-statistics', { params });
        return response.data;
    }
}
