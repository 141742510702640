<template>
  <div class="py-4 container-fluid text-center">
    <h1 class="text-center text-primary mb-4">Dados Gerais</h1>

    <div class="row">

      <!-- Colaboradores -->
      <div class="col-md-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="text-muted">Colaboradores</h5>
            <h3 class="text-dark">{{ colaboradores }}</h3>
          </div>
        </div>
      </div>

      <!-- Triagens realizadas -->
      <div class="col-md-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="text-muted">Triagens Realizadas</h5>
            <h3 class="text-success">{{ triagens }}</h3>
            <p class="text-primary">{{ triagensPorcentagem }}% de triagens completadas</p>
          </div>
        </div>
      </div>

      <!-- Exames realizados -->
      <div class="col-md-4">
        <div class="card shadow-sm">
          <div class="card-body">
            <h5 class="text-muted">Exames Realizados</h5>
            <h3 class="text-info">{{ exames }}</h3>
            <p class="text-secondary">{{ examesPorcentagem }}% dos exames realizados</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DadosGerais",
  props: {
    empresas: {
      type: Number,
      required: true,
    },
    colaboradores: {
      type: Number,
      required: true,
    },
    triagens: {
      type: Number,
      required: true,
    },
    triagensPorcentagem: {
      type: Number,
      required: true,
    },
    exames: {
      type: Number,
      required: true,
    },
    examesPorcentagem: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style scoped>
.row {
  display: flex;
  justify-content: space-between;
}

h1 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
  font-weight: bold;
}

.card {
  border: none;
  border-radius: 10px;
  background: white;
}

.card-body {
  height: 180px; /* Define a altura fixa para os cards */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-body h5 {
  font-size: 1.2rem;
}

.card-body p {
  font-size: 1rem;
}
</style>
