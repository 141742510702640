import examsService from "../services/exams.service"
import { parseFormError } from "../services/utils";

const initialState = {
  exams: {},
  loading: false,
  modal: {
    open: false,
    patientId: null,
  },
  form: {
    open: false,
    patientId: null,
    exam: null,
    error: null
  }
}

export const exams = {
  namespaced: true,
  state: initialState,
  actions: {
    async getExams({ commit }, patientId) {
      commit("SET_LOADING", true);
      const response = await examsService.getExams(patientId);
      const data = { patientId, exams: response };
      commit("SET_EXAMS", data);
    },
    async showModal({ commit, dispatch }, patientId) {
      const value = { open: true, patientId };
      commit("SET_MODAL", value);
      await dispatch('getExams', patientId)
    },
    async closeModal({ commit }) {
      const value = { open: false, patientId: null };
      commit("SET_MODAL", value);
    },
    startAdding({ commit }, patientId) {
      const value = { open: true, patientId, exam: null };
      commit("SET_FORM", value);
    },
    async addExam({ commit, dispatch }, { patientId, exam }) {
      try {
        commit("SET_FORM_ERROR", null);
        await examsService.addExam(patientId, exam);
        dispatch("stopEditing");
        dispatch("getExams", patientId);
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async addRetinography({ commit, dispatch }, { retinography }) {
      try {
        commit("SET_FORM_ERROR", null);
        await examsService.addRetinography(retinography);
        dispatch("stopEditing");
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async startEditing({ commit }, { exam, patientId }) {
      const value = { open: true, exam, patientId };
      commit("SET_FORM", value);
    },
    async updateExam({ commit, dispatch }, { patientId, exam }) {
      try {
        commit("SET_FORM_ERROR", null);
        await examsService.updateExam(patientId, exam);
        dispatch("stopEditing");
        dispatch("getExams", patientId);
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, exam: null, patientId: null };
      commit("SET_FORM", value);
    },

    async sendExamResult({ commit }, examId) {
      commit("SET_LOADING", true);
      try {
        const response = await examsService.sendExamResult(examId);
        
        return response;
      } catch (error) {
        console.error("Erro ao enviar e-mail:", error);
        throw error;
      } finally {
        commit("SET_LOADING", false);
      }
    },
  },
  mutations: {
    SET_EXAMS(state, { patientId, exams }) {
      state.exams[patientId] = exams
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_MODAL(state, { open, patientId }) {
      state.modal.open = open;
      state.modal.patientId = patientId;
    },
    SET_FORM(state, { open, exam, patientId }) {
      state.form.open = open;
      state.form.patientId = patientId;
      state.form.exam = exam;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.exams = [];
      state.loading = false;
      state.form = {
        open: false,
        exam: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.exams.length;
    }
  }
}
