import triagesService from "../services/triages.service"
import { parseFormError } from "../services/utils";

const initialState = {
  triages: {},
  loading: false,
  modal: {
    open: false,
    patientId: null,
  },
  form: {
    open: false,
    patientId: null,
    triage: null,
    error: null
  }
}

export const triages = {
  namespaced: true,
  state: initialState,
  actions: {
    async getTriages({ commit }, patientId) {
      commit("SET_LOADING", true);
      const response = await triagesService.getTriages(patientId);
      const data = { patientId, triages: response };
      commit("SET_TRIAGES", data);
    },
    async showModal({ commit, dispatch }, patientId) {
      const value = { open: true, patientId };
      commit("SET_MODAL", value);
      await dispatch('getTriages', patientId)
    },
    async closeModal({ commit }) {
      const value = { open: false, patientId: null };
      commit("SET_MODAL", value);
    },
    startAdding({ commit }, patientId) {
      if (!patientId) {
        patientId = localStorage.getItem("patient_id");
      }
      const value = { open: true, patientId, triage: null };
      commit("SET_FORM", value);
    },
    async addTriage({ commit, dispatch }, { patientId, triage }) {
      try {
        commit("SET_FORM_ERROR", null);
        const response = await triagesService.addTriage(patientId, triage);
        dispatch("stopEditing");
        dispatch("getTriages", patientId);
        return response;
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async startEditing({ commit }, { triage, patientId }) {
      const value = { open: true, triage, patientId };
      commit("SET_FORM", value);
    },
    async updateTriage({ commit, dispatch }, { patientId, triage }) {
      try {
        commit("SET_FORM_ERROR", null);
        await triagesService.updateTriage(patientId, triage);
        dispatch("stopEditing");
        dispatch("getTriages", patientId);
      } catch (error) {
        commit("SET_FORM_ERROR", parseFormError(error));
      }
    },
    async stopEditing({ commit }) {
      const value = { open: false, triage: null, patientId: null };
      commit("SET_FORM", value);
    },
  },
  mutations: {
    SET_TRIAGES(state, { patientId, triages }) {
      state.triages[patientId] = triages
      state.loading = false
    },
    SET_LOADING(state, value) {
      state.loading = value
    },
    SET_MODAL(state, { open, patientId }) {
      state.modal.open = open;
      state.modal.patientId = patientId;
    },
    SET_FORM(state, { open, triage, patientId }) {
      state.form.open = open;
      state.form.patientId = patientId;
      state.form.triage = triage;
    },
    SET_FORM_ERROR(state, error) {
      state.form.error = error
    },
    resetState(state) {
      state.triages = [];
      state.loading = false;
      state.form = {
        open: false,
        triage: null,
      };
    }
  },
  getters: {
    total(state) {
      return state.triages.length;
    }
  }
}
