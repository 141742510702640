<!-- O gráfico de barras é ideal para comparar diferentes categorias de dados. -->
<template>
  <TypedChart
    :type="'bar'"
    :data="data"
    :options="options"
  />
</template>

<script>

import { Bar } from 'vue-chartjs';

export default {
  name: 'BarChart',
  components: { TypedChart: Bar },
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>